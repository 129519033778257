.spinner {
  margin: 350px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgb(var(--palette-m3-system-blue-40));

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

.skeleton-loading {
  .title-loading,
  .comment-loading,
  .avatar-loading {
    animation: skeleton-loading 2s infinite linear;
    background: $gray-600;
    background: linear-gradient(
      to right,
      $gray-200 4%,
      $gray-300 25%,
      $gray-200 36%
    );
    background-size: 1000px 100%;
  }

  .avatar-loading {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  .comment-loading,
  .title-loading {
    border-radius: 4px;
    height: $app-small-line-height;
  }

  .title-loading {
    height: $app-h4-line-height;
  }
}
