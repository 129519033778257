mat-slider,
mat-slider:hover {
  &:not(.mat-slider-disabled) {
    &.mat-accent,
    &.mat-primary {
      .mat-slider-track-background {
        opacity: 0.38;
      }
    }

    &.mat-accent {
      .mat-slider-track-background {
        background-color: rgb(var(--palette-accent-500));
      }
    }

    &.mat-primary {
      .mat-slider-track-background {
        background-color: rgb(var(--palette-primary-500));
      }
    }
  }
}
