@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  @import '_styles.base.scss';
  @import '_styles.unreset.scss';
}

@layer utilities {
  .avatar-standard-desktop-elevation {
    @include mat.elevation(4);
  }

  @import '_styles.divider.scss';
}
