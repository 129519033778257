@font-face {
  font-family: 'Simple-Line-Icons-Pro';
  src: url('#{$simple-line-icon-font-path}/Simple-Line-Icons-Pro.eot?3rp9rz');
  src: url('#{$simple-line-icon-font-path}/Simple-Line-Icons-Pro.eot?3rp9rz#iefix')
      format('embedded-opentype'),
    url('#{$simple-line-icon-font-path}/Simple-Line-Icons-Pro.ttf?3rp9rz')
      format('truetype'),
    url('#{$simple-line-icon-font-path}/Simple-Line-Icons-Pro.woff?3rp9rz')
      format('woff'),
    url('#{$simple-line-icon-font-path}/Simple-Line-Icons-Pro.svg?3rp9rz#Simple-Line-Icons-Pro')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'Simple-Line-Icons-Pro';
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-1-circle-down:before {
  content: '\e800';
}
.icon-arrow-1-circle-left:before {
  content: '\e801';
}
.icon-arrow-1-circle-right:before {
  content: '\e802';
}
.icon-arrow-1-circle-up:before {
  content: '\e803';
}
.icon-arrow-1-down:before {
  content: '\e804';
}
.icon-arrow-1-left:before {
  content: '\e805';
}
.icon-arrow-1-right:before {
  content: '\e806';
}
.icon-arrow-1-square-down:before {
  content: '\e807';
}
.icon-arrow-1-square-left:before {
  content: '\e808';
}
.icon-arrow-1-square-right:before {
  content: '\e809';
}
.icon-arrow-1-square-up:before {
  content: '\e80a';
}
.icon-arrow-1-up:before {
  content: '\e80b';
}
.icon-arrow-2-circle-down:before {
  content: '\e80c';
}
.icon-arrow-2-circle-left:before {
  content: '\e80d';
}
.icon-arrow-2-circle-right:before {
  content: '\e80e';
}
.icon-arrow-2-circle-up:before {
  content: '\e80f';
}
.icon-arrow-2-down:before {
  content: '\e810';
}
.icon-arrow-2-left:before {
  content: '\e811';
}
.icon-arrow-2-right:before {
  content: '\e812';
}
.icon-arrow-2-sqare-down:before {
  content: '\e813';
}
.icon-arrow-2-sqare-left:before {
  content: '\e814';
}
.icon-arrow-2-sqare-right:before {
  content: '\e815';
}
.icon-arrow-2-sqare-up:before {
  content: '\e816';
}
.icon-arrow-2-up:before {
  content: '\e817';
}
.icon-arrow-3-circle-down:before {
  content: '\e818';
}
.icon-arrow-3-circle-left:before {
  content: '\e819';
}
.icon-arrow-3-circle-right:before {
  content: '\e81a';
}
.icon-arrow-3-circle-up:before {
  content: '\e81b';
}
.icon-arrow-3-down:before {
  content: '\e81c';
}
.icon-arrow-3-left:before {
  content: '\e81d';
}
.icon-arrow-3-right:before {
  content: '\e81e';
}
.icon-arrow-3-square-down:before {
  content: '\e81f';
}
.icon-arrow-3-square-left:before {
  content: '\e820';
}
.icon-arrow-3-square-right:before {
  content: '\e821';
}
.icon-arrow-3-square-up:before {
  content: '\e822';
}
.icon-arrow-3-up:before {
  content: '\e823';
}
.icon-back-1:before {
  content: '\e824';
}
.icon-back-2:before {
  content: '\e825';
}
.icon-back-3:before {
  content: '\e826';
}
.icon-back-4-circle:before {
  content: '\e827';
}
.icon-back-4-square:before {
  content: '\e828';
}
.icon-back-4:before {
  content: '\e829';
}
.icon-back-5:before {
  content: '\e82a';
}
.icon-back-6:before {
  content: '\e82b';
}
.icon-back-7:before {
  content: '\e82c';
}
.icon-backward-7:before {
  content: '\e82d';
}
.icon-double-arrows-1-left:before {
  content: '\e82e';
}
.icon-double-arrows-1-right:before {
  content: '\e82f';
}
.icon-fork-arrows:before {
  content: '\e830';
}
.icon-forward-7:before {
  content: '\e831';
}
.icon-next-1:before {
  content: '\e832';
}
.icon-next-2:before {
  content: '\e833';
}
.icon-next-3:before {
  content: '\e834';
}
.icon-next-4-circle:before {
  content: '\e835';
}
.icon-next-4-square:before {
  content: '\e836';
}
.icon-next-4:before {
  content: '\e837';
}
.icon-next-5:before {
  content: '\e838';
}
.icon-next-6:before {
  content: '\e839';
}
.icon-next-7:before {
  content: '\e83a';
}
.icon-s-arrow-1:before {
  content: '\e83b';
}
.icon-s-arrow-2:before {
  content: '\e83c';
}
.icon-s-arrow-3:before {
  content: '\e83d';
}
.icon-answer:before {
  content: '\e83e';
}
.icon-call-back:before {
  content: '\e83f';
}
.icon-call-block:before {
  content: '\e840';
}
.icon-call-end:before {
  content: '\e841';
}
.icon-call-hold:before {
  content: '\e842';
}
.icon-call-in:before {
  content: '\e843';
}
.icon-call-logs:before {
  content: '\e844';
}
.icon-call-off:before {
  content: '\e845';
}
.icon-call-out:before {
  content: '\e846';
}
.icon-call:before {
  content: '\e847';
}
.icon-chat-1:before {
  content: '\e848';
}
.icon-chat-2:before {
  content: '\e849';
}
.icon-chat-3:before {
  content: '\e84a';
}
.icon-comment-1:before {
  content: '\e84b';
}
.icon-comment-2-hashtag:before {
  content: '\e84c';
}
.icon-comment-2-quote:before {
  content: '\e84d';
}
.icon-comment-2-smile:before {
  content: '\e84e';
}
.icon-comment-2:before {
  content: '\e84f';
}
.icon-comments-1:before {
  content: '\e850';
}
.icon-comments-2:before {
  content: '\e851';
}
.icon-comments-3:before {
  content: '\e852';
}
.icon-comments-4:before {
  content: '\e853';
}
.icon-email-2-at:before {
  content: '\e854';
}
.icon-email-2-letter:before {
  content: '\e855';
}
.icon-email-2-open:before {
  content: '\e856';
}
.icon-email-2-search:before {
  content: '\e857';
}
.icon-email-at:before {
  content: '\e858';
}
.icon-email-ban:before {
  content: '\e859';
}
.icon-email-check:before {
  content: '\e85a';
}
.icon-email-close:before {
  content: '\e85b';
}
.icon-email-delete:before {
  content: '\e85c';
}
.icon-email-edit:before {
  content: '\e85d';
}
.icon-email-forward:before {
  content: '\e85e';
}
.icon-email-in:before {
  content: '\e85f';
}
.icon-email-letter:before {
  content: '\e860';
}
.icon-email-locked:before {
  content: '\e861';
}
.icon-email-new:before {
  content: '\e862';
}
.icon-email-open:before {
  content: '\e863';
}
.icon-email-out:before {
  content: '\e864';
}
.icon-email-refresh:before {
  content: '\e865';
}
.icon-email-reply:before {
  content: '\e866';
}
.icon-email-search:before {
  content: '\e867';
}
.icon-email-send:before {
  content: '\e868';
}
.icon-email-star:before {
  content: '\e869';
}
.icon-email:before {
  content: '\e86a';
}
.icon-inbox-empty:before {
  content: '\e86b';
}
.icon-inbox-full:before {
  content: '\e86c';
}
.icon-inbox:before {
  content: '\e86d';
}
.icon-letter:before {
  content: '\e86e';
}
.icon-letters:before {
  content: '\e86f';
}
.icon-megaphone-1:before {
  content: '\e870';
}
.icon-megaphone-2:before {
  content: '\e871';
}
.icon-message-1-alert:before {
  content: '\e872';
}
.icon-message-1-ask:before {
  content: '\e873';
}
.icon-message-1-hashtag:before {
  content: '\e874';
}
.icon-message-1-heart:before {
  content: '\e875';
}
.icon-message-1-like:before {
  content: '\e876';
}
.icon-message-1-music-tone:before {
  content: '\e877';
}
.icon-message-1-pause:before {
  content: '\e878';
}
.icon-message-1-quote:before {
  content: '\e879';
}
.icon-message-1-smile:before {
  content: '\e87a';
}
.icon-message-1-write:before {
  content: '\e87b';
}
.icon-message-1:before {
  content: '\e87c';
}
.icon-message-2-alert:before {
  content: '\e87d';
}
.icon-message-2-ask:before {
  content: '\e87e';
}
.icon-message-2-heart:before {
  content: '\e87f';
}
.icon-message-2-like:before {
  content: '\e880';
}
.icon-message-2-music-tone:before {
  content: '\e881';
}
.icon-message-2-pause:before {
  content: '\e882';
}
.icon-message-2-write-2:before {
  content: '\e883';
}
.icon-message-2-write:before {
  content: '\e884';
}
.icon-message-2:before {
  content: '\e885';
}
.icon-message-3-alert:before {
  content: '\e886';
}
.icon-message-3-ask:before {
  content: '\e887';
}
.icon-message-3-hashtag:before {
  content: '\e888';
}
.icon-message-3-heart:before {
  content: '\e889';
}
.icon-message-3-like:before {
  content: '\e88a';
}
.icon-message-3-music-tone:before {
  content: '\e88b';
}
.icon-message-3-pause:before {
  content: '\e88c';
}
.icon-message-3-quote:before {
  content: '\e88d';
}
.icon-message-3-smile:before {
  content: '\e88e';
}
.icon-message-3-write-2:before {
  content: '\e88f';
}
.icon-message-3-write:before {
  content: '\e890';
}
.icon-message-3:before {
  content: '\e891';
}
.icon-outbox:before {
  content: '\e892';
}
.icon-paper-plane-2:before {
  content: '\e893';
}
.icon-phone-call-in:before {
  content: '\e894';
}
.icon-phone-call-out:before {
  content: '\e895';
}
.icon-phone-contacts:before {
  content: '\e896';
}
.icon-phone-message-1:before {
  content: '\e897';
}
.icon-phone-message-2:before {
  content: '\e898';
}
.icon-phone-message-3:before {
  content: '\e899';
}
.icon-phone-ring:before {
  content: '\e89a';
}
.icon-Q-and-A:before {
  content: '\e89b';
}
.icon-telephone-1:before {
  content: '\e89c';
}
.icon-voice-mail:before {
  content: '\e89d';
}
.icon-battery-1:before {
  content: '\e89e';
}
.icon-battery-2:before {
  content: '\e89f';
}
.icon-battery-3:before {
  content: '\e8a0';
}
.icon-battery-4:before {
  content: '\e8a1';
}
.icon-battery-charging:before {
  content: '\e8a2';
}
.icon-battery-empty:before {
  content: '\e8a3';
}
.icon-battery-fully-charged:before {
  content: '\e8a4';
}
.icon-battery-low:before {
  content: '\e8a5';
}
.icon-battery-warning:before {
  content: '\e8a6';
}
.icon-cable-1:before {
  content: '\e8a7';
}
.icon-cable-2:before {
  content: '\e8a8';
}
.icon-cd-burn:before {
  content: '\e8a9';
}
.icon-cd:before {
  content: '\e8aa';
}
.icon-charger-plug-1:before {
  content: '\e8ab';
}
.icon-charger-plug-2:before {
  content: '\e8ac';
}
.icon-charger-plug-3:before {
  content: '\e8ad';
}
.icon-desktop:before {
  content: '\e8ae';
}
.icon-diskette-1:before {
  content: '\e8af';
}
.icon-diskette-2:before {
  content: '\e8b0';
}
.icon-diskette-3:before {
  content: '\e8b1';
}
.icon-flashdrive:before {
  content: '\e8b2';
}
.icon-gameboy:before {
  content: '\e8b3';
}
.icon-hdd:before {
  content: '\e8b4';
}
.icon-imac:before {
  content: '\e8b5';
}
.icon-ipod:before {
  content: '\e8b6';
}
.icon-iwatch:before {
  content: '\e8b7';
}
.icon-joystick-1:before {
  content: '\e8b8';
}
.icon-joystick-2:before {
  content: '\e8b9';
}
.icon-keyboard-1:before {
  content: '\e8ba';
}
.icon-keyboard-2:before {
  content: '\e8bb';
}
.icon-laptop-1:before {
  content: '\e8bc';
}
.icon-laptop-2:before {
  content: '\e8bd';
}
.icon-monitor:before {
  content: '\e8be';
}
.icon-mouse-1:before {
  content: '\e8bf';
}
.icon-mouse-2:before {
  content: '\e8c0';
}
.icon-mouse-3:before {
  content: '\e8c1';
}
.icon-old-computer:before {
  content: '\e8c2';
}
.icon-old-radio-1:before {
  content: '\e8c3';
}
.icon-old-radio-2:before {
  content: '\e8c4';
}
.icon-old-radio-3:before {
  content: '\e8c5';
}
.icon-old-telephone:before {
  content: '\e8c6';
}
.icon-old-tv-1:before {
  content: '\e8c7';
}
.icon-old-tv-2:before {
  content: '\e8c8';
}
.icon-outlet:before {
  content: '\e8c9';
}
.icon-plug:before {
  content: '\e8ca';
}
.icon-printer:before {
  content: '\e8cb';
}
.icon-projector:before {
  content: '\e8cc';
}
.icon-psp:before {
  content: '\e8cd';
}
.icon-remote:before {
  content: '\e8ce';
}
.icon-router:before {
  content: '\e8cf';
}
.icon-security-camera:before {
  content: '\e8d0';
}
.icon-shredder:before {
  content: '\e8d1';
}
.icon-sim-1:before {
  content: '\e8d2';
}
.icon-sim-2:before {
  content: '\e8d3';
}
.icon-smart-watch:before {
  content: '\e8d4';
}
.icon-smartphone-3G:before {
  content: '\e8d5';
}
.icon-smartphone-4G:before {
  content: '\e8d6';
}
.icon-smartphone-desktop:before {
  content: '\e8d7';
}
.icon-smartphone-hand-1:before {
  content: '\e8d8';
}
.icon-smartphone-hand-2:before {
  content: '\e8d9';
}
.icon-smartphone-landscape:before {
  content: '\e8da';
}
.icon-smartphone-laptop:before {
  content: '\e8db';
}
.icon-smartphone-off:before {
  content: '\e8dc';
}
.icon-smartphone-orientation:before {
  content: '\e8dd';
}
.icon-smartphone-rotate-left:before {
  content: '\e8de';
}
.icon-smartphone-rotate-right:before {
  content: '\e8df';
}
.icon-smartphone-tablet-1:before {
  content: '\e8e0';
}
.icon-smartphone-tablet-2:before {
  content: '\e8e1';
}
.icon-smartphone-tablet-desktop:before {
  content: '\e8e2';
}
.icon-smartphone:before {
  content: '\e8e3';
}
.icon-smartphones:before {
  content: '\e8e4';
}
.icon-switch:before {
  content: '\e8e5';
}
.icon-tablet-desktop:before {
  content: '\e8e6';
}
.icon-tablet-landscape:before {
  content: '\e8e7';
}
.icon-tablet-orientation-landscape:before {
  content: '\e8e8';
}
.icon-tablet-orientation-portrait:before {
  content: '\e8e9';
}
.icon-tablet-stylus:before {
  content: '\e8ea';
}
.icon-tablet:before {
  content: '\e8eb';
}
.icon-tablets:before {
  content: '\e8ec';
}
.icon-telephone:before {
  content: '\e8ed';
}
.icon-tv:before {
  content: '\e8ee';
}
.icon-usb-wireless:before {
  content: '\e8ef';
}
.icon-web-camera:before {
  content: '\e8f0';
}
.icon-auction:before {
  content: '\e8f1';
}
.icon-barcode-scan:before {
  content: '\e8f2';
}
.icon-barcode:before {
  content: '\e8f3';
}
.icon-basket-add:before {
  content: '\e8f4';
}
.icon-basket-checked:before {
  content: '\e8f5';
}
.icon-basket-close:before {
  content: '\e8f6';
}
.icon-basket-out-in:before {
  content: '\e8f7';
}
.icon-basket-out:before {
  content: '\e8f8';
}
.icon-basket-remove:before {
  content: '\e8f9';
}
.icon-basket:before {
  content: '\e8fa';
}
.icon-cart-1-add:before {
  content: '\e8fb';
}
.icon-cart-1-cancel:before {
  content: '\e8fc';
}
.icon-cart-1-checked:before {
  content: '\e8fd';
}
.icon-cart-1-in:before {
  content: '\e8fe';
}
.icon-cart-1-loaded:before {
  content: '\e8ff';
}
.icon-cart-1-out:before {
  content: '\e900';
}
.icon-cart-1-remove:before {
  content: '\e901';
}
.icon-cart-1:before {
  content: '\e902';
}
.icon-cart-2-add:before {
  content: '\e903';
}
.icon-cart-2-cancel:before {
  content: '\e904';
}
.icon-cart-2-checked:before {
  content: '\e905';
}
.icon-cart-2-in:before {
  content: '\e906';
}
.icon-cart-2-loaded:before {
  content: '\e907';
}
.icon-cart-2-out:before {
  content: '\e908';
}
.icon-cart-2-remove:before {
  content: '\e909';
}
.icon-cart-2:before {
  content: '\e90a';
}
.icon-cart-3-loaded:before {
  content: '\e90b';
}
.icon-cart-3:before {
  content: '\e90c';
}
.icon-delivery-1:before {
  content: '\e90d';
}
.icon-delivery-2:before {
  content: '\e90e';
}
.icon-delivery-3:before {
  content: '\e90f';
}
.icon-delivery-box-1:before {
  content: '\e910';
}
.icon-delivery-box-2:before {
  content: '\e911';
}
.icon-discount-circle:before {
  content: '\e912';
}
.icon-discount-star:before {
  content: '\e913';
}
.icon-handbag:before {
  content: '\e914';
}
.icon-list-heart:before {
  content: '\e915';
}
.icon-open-sign:before {
  content: '\e916';
}
.icon-price-tag:before {
  content: '\e917';
}
.icon-qr-code:before {
  content: '\e918';
}
.icon-shop-1:before {
  content: '\e919';
}
.icon-shop-2-location:before {
  content: '\e91a';
}
.icon-shop-2:before {
  content: '\e91b';
}
.icon-shopping-bag-add:before {
  content: '\e91c';
}
.icon-shopping-bag-checked:before {
  content: '\e91d';
}
.icon-shopping-bag-close:before {
  content: '\e91e';
}
.icon-shopping-bag-heart:before {
  content: '\e91f';
}
.icon-shopping-bag-remove:before {
  content: '\e920';
}
.icon-shopping-bag:before {
  content: '\e921';
}
.icon-shopping-tag:before {
  content: '\e922';
}
.icon-shopping-tags:before {
  content: '\e923';
}
.icon-ticket:before {
  content: '\e924';
}
.icon-wallet-1:before {
  content: '\e925';
}
.icon-wallet-2:before {
  content: '\e926';
}
.icon-wallet-add:before {
  content: '\e927';
}
.icon-wallet-ban:before {
  content: '\e928';
}
.icon-wallet-cancel:before {
  content: '\e929';
}
.icon-wallet-info:before {
  content: '\e92a';
}
.icon-wallet-loaded:before {
  content: '\e92b';
}
.icon-wallet-locked:before {
  content: '\e92c';
}
.icon-wallet-remove:before {
  content: '\e92d';
}
.icon-wallet-verified:before {
  content: '\e92e';
}
.icon-abacus:before {
  content: '\e92f';
}
.icon-alphabet:before {
  content: '\e930';
}
.icon-blackboard-1:before {
  content: '\e931';
}
.icon-blackboard-2:before {
  content: '\e932';
}
.icon-blackboard-3:before {
  content: '\e933';
}
.icon-blackboard-alphabet:before {
  content: '\e934';
}
.icon-blackboard-pointer:before {
  content: '\e935';
}
.icon-bomb:before {
  content: '\e936';
}
.icon-briefcase-2:before {
  content: '\e937';
}
.icon-bulb-add:before {
  content: '\e938';
}
.icon-bulb-ckecked:before {
  content: '\e939';
}
.icon-bulb-close:before {
  content: '\e93a';
}
.icon-bulb-idea:before {
  content: '\e93b';
}
.icon-bulb-remove:before {
  content: '\e93c';
}
.icon-bulb:before {
  content: '\e93d';
}
.icon-chemistry-1-test-failed:before {
  content: '\e93e';
}
.icon-chemistry-1-test-successful:before {
  content: '\e93f';
}
.icon-chemistry-1:before {
  content: '\e940';
}
.icon-chemistry-2:before {
  content: '\e941';
}
.icon-chemistry-3:before {
  content: '\e942';
}
.icon-chemistry-5:before {
  content: '\e943';
}
.icon-divider:before {
  content: '\e944';
}
.icon-drawers:before {
  content: '\e945';
}
.icon-earth-globe:before {
  content: '\e946';
}
.icon-formula-2:before {
  content: '\e947';
}
.icon-formula:before {
  content: '\e948';
}
.icon-germs:before {
  content: '\e949';
}
.icon-grade:before {
  content: '\e94a';
}
.icon-graduation-cap:before {
  content: '\e94b';
}
.icon-learning:before {
  content: '\e94c';
}
.icon-math:before {
  content: '\e94d';
}
.icon-molecule:before {
  content: '\e94e';
}
.icon-nerd-glasses:before {
  content: '\e94f';
}
.icon-physics-1:before {
  content: '\e950';
}
.icon-physics-2:before {
  content: '\e951';
}
.icon-planet:before {
  content: '\e952';
}
.icon-school-bag:before {
  content: '\e953';
}
.icon-telescope:before {
  content: '\e954';
}
.icon-university:before {
  content: '\e955';
}
.icon-7-support-1:before {
  content: '\e956';
}
.icon-7-support-2:before {
  content: '\e957';
}
.icon-h-calls:before {
  content: '\e958';
}
.icon-ATM-1:before {
  content: '\e959';
}
.icon-ATM-2:before {
  content: '\e95a';
}
.icon-balance:before {
  content: '\e95b';
}
.icon-bank:before {
  content: '\e95c';
}
.icon-banknote-1:before {
  content: '\e95d';
}
.icon-banknote-2:before {
  content: '\e95e';
}
.icon-banknote-coins:before {
  content: '\e95f';
}
.icon-banknotes-1:before {
  content: '\e960';
}
.icon-banknotes-2:before {
  content: '\e961';
}
.icon-bar-chart-board:before {
  content: '\e962';
}
.icon-bar-chart-down:before {
  content: '\e963';
}
.icon-bar-chart-search:before {
  content: '\e964';
}
.icon-bar-chart-stats-down:before {
  content: '\e965';
}
.icon-bar-chart-stats-up:before {
  content: '\e966';
}
.icon-bar-chart-up:before {
  content: '\e967';
}
.icon-bar-chart:before {
  content: '\e968';
}
.icon-bill-1:before {
  content: '\e969';
}
.icon-bill-2:before {
  content: '\e96a';
}
.icon-bitcoin:before {
  content: '\e96b';
}
.icon-bitcoint-circle:before {
  content: '\e96c';
}
.icon-briefcase:before {
  content: '\e96d';
}
.icon-calculator:before {
  content: '\e96e';
}
.icon-calendar-money:before {
  content: '\e96f';
}
.icon-cent-circle:before {
  content: '\e970';
}
.icon-cent:before {
  content: '\e971';
}
.icon-coins-1:before {
  content: '\e972';
}
.icon-coins-2:before {
  content: '\e973';
}
.icon-coins-3:before {
  content: '\e974';
}
.icon-coins-4:before {
  content: '\e975';
}
.icon-credit-card-2:before {
  content: '\e976';
}
.icon-credit-card:before {
  content: '\e977';
}
.icon-currency-exchange:before {
  content: '\e978';
}
.icon-donut-chart-1:before {
  content: '\e979';
}
.icon-donut-chart-2:before {
  content: '\e97a';
}
.icon-EUR-circle:before {
  content: '\e97b';
}
.icon-EUR:before {
  content: '\e97c';
}
.icon-GBP-circle:before {
  content: '\e97d';
}
.icon-GBP:before {
  content: '\e97e';
}
.icon-gold-1:before {
  content: '\e97f';
}
.icon-gold-2:before {
  content: '\e980';
}
.icon-graph-2:before {
  content: '\e981';
}
.icon-graph-chart-board-down:before {
  content: '\e982';
}
.icon-graph-chart-board-up:before {
  content: '\e983';
}
.icon-graph-chart-board:before {
  content: '\e984';
}
.icon-graph-down:before {
  content: '\e985';
}
.icon-graph-money:before {
  content: '\e986';
}
.icon-graph-up:before {
  content: '\e987';
}
.icon-graph:before {
  content: '\e988';
}
.icon-hand-banknote:before {
  content: '\e989';
}
.icon-hand-banknotes:before {
  content: '\e98a';
}
.icon-hand-bill-1:before {
  content: '\e98b';
}
.icon-hand-bill-2:before {
  content: '\e98c';
}
.icon-hand-coin:before {
  content: '\e98d';
}
.icon-hand-coins:before {
  content: '\e98e';
}
.icon-hand-credit-card:before {
  content: '\e98f';
}
.icon-JPY-circle:before {
  content: '\e990';
}
.icon-JPY:before {
  content: '\e991';
}
.icon-money-bag-coins:before {
  content: '\e992';
}
.icon-money-bag:before {
  content: '\e993';
}
.icon-money-hierarchy:before {
  content: '\e994';
}
.icon-networking:before {
  content: '\e995';
}
.icon-pie-chart-1:before {
  content: '\e996';
}
.icon-pie-chart-2:before {
  content: '\e997';
}
.icon-pie-chart-3:before {
  content: '\e998';
}
.icon-pie-chart-board:before {
  content: '\e999';
}
.icon-piggy-bank:before {
  content: '\e99a';
}
.icon-presentation:before {
  content: '\e99b';
}
.icon-safe:before {
  content: '\e99c';
}
.icon-search-money:before {
  content: '\e99d';
}
.icon-search-stats-1:before {
  content: '\e99e';
}
.icon-send-money:before {
  content: '\e99f';
}
.icon-shaking-hands:before {
  content: '\e9a0';
}
.icon-speech-buble-money:before {
  content: '\e9a1';
}
.icon-stamp:before {
  content: '\e9a2';
}
.icon-support:before {
  content: '\e9a3';
}
.icon-target-1:before {
  content: '\e9a4';
}
.icon-target-2:before {
  content: '\e9a5';
}
.icon-target-3:before {
  content: '\e9a6';
}
.icon-target-4:before {
  content: '\e9a7';
}
.icon-target-money:before {
  content: '\e9a8';
}
.icon-tasks-1:before {
  content: '\e9a9';
}
.icon-tasks-2:before {
  content: '\e9aa';
}
.icon-tasks-3:before {
  content: '\e9ab';
}
.icon-tasks-checked:before {
  content: '\e9ac';
}
.icon-tie:before {
  content: '\e9ad';
}
.icon-time-money:before {
  content: '\e9ae';
}
.icon-USD-circle:before {
  content: '\e9af';
}
.icon-USD:before {
  content: '\e9b0';
}
.icon-voucher:before {
  content: '\e9b1';
}
.icon-workflow:before {
  content: '\e9b2';
}
.icon-write-check:before {
  content: '\e9b3';
}
.icon-d-axis:before {
  content: '\e9b4';
}
.icon-d-axis-2:before {
  content: '\e9b5';
}
.icon-d-axis2:before {
  content: '\e9b6';
}
.icon-D-cube:before {
  content: '\e9b7';
}
.icon-blur:before {
  content: '\e9b8';
}
.icon-bring-forward:before {
  content: '\e9b9';
}
.icon-brush-1:before {
  content: '\e9ba';
}
.icon-brush-2:before {
  content: '\e9bb';
}
.icon-brush-pencil:before {
  content: '\e9bc';
}
.icon-cmyk:before {
  content: '\e9bd';
}
.icon-color-palette:before {
  content: '\e9be';
}
.icon-crop:before {
  content: '\e9bf';
}
.icon-easel:before {
  content: '\e9c0';
}
.icon-eraser:before {
  content: '\e9c1';
}
.icon-eyedropper-1:before {
  content: '\e9c2';
}
.icon-eyedropper-2:before {
  content: '\e9c3';
}
.icon-golden-spiral:before {
  content: '\e9c4';
}
.icon-graphic-tablet:before {
  content: '\e9c5';
}
.icon-grid:before {
  content: '\e9c6';
}
.icon-layers-1:before {
  content: '\e9c7';
}
.icon-layers-2:before {
  content: '\e9c8';
}
.icon-layers-add-1:before {
  content: '\e9c9';
}
.icon-layers-add-2:before {
  content: '\e9ca';
}
.icon-layers-linked--1:before {
  content: '\e9cb';
}
.icon-layers-linked-2:before {
  content: '\e9cc';
}
.icon-layers-locked-1:before {
  content: '\e9cd';
}
.icon-layers-locked-2:before {
  content: '\e9ce';
}
.icon-layers-off-1:before {
  content: '\e9cf';
}
.icon-layers-remove-1:before {
  content: '\e9d0';
}
.icon-layers-remove-2:before {
  content: '\e9d1';
}
.icon-paint-bucket-1:before {
  content: '\e9d2';
}
.icon-paint-bucket-2:before {
  content: '\e9d3';
}
.icon-paint-roll:before {
  content: '\e9d4';
}
.icon-pantone-charts:before {
  content: '\e9d5';
}
.icon-pathfinder-exclude:before {
  content: '\e9d6';
}
.icon-pathfinder-intersect:before {
  content: '\e9d7';
}
.icon-pathfinder-minus-front:before {
  content: '\e9d8';
}
.icon-pathfinder-unite:before {
  content: '\e9d9';
}
.icon-pen-2:before {
  content: '\e9da';
}
.icon-pen-pencil:before {
  content: '\e9db';
}
.icon-pen1:before {
  content: '\e9dc';
}
.icon-pencil-ruler:before {
  content: '\e9dd';
}
.icon-pencil1:before {
  content: '\e9de';
}
.icon-pencil2:before {
  content: '\e9df';
}
.icon-rgb:before {
  content: '\e9e0';
}
.icon-ruler-triangle:before {
  content: '\e9e1';
}
.icon-ruler:before {
  content: '\e9e2';
}
.icon-scissors-2:before {
  content: '\e9e3';
}
.icon-scissors:before {
  content: '\e9e4';
}
.icon-send-backward:before {
  content: '\e9e5';
}
.icon-sharpener:before {
  content: '\e9e6';
}
.icon-smart-object:before {
  content: '\e9e7';
}
.icon-spiral:before {
  content: '\e9e8';
}
.icon-spray-can:before {
  content: '\e9e9';
}
.icon-square-circle:before {
  content: '\e9ea';
}
.icon-square-triangle-circle:before {
  content: '\e9eb';
}
.icon-square-triangle:before {
  content: '\e9ec';
}
.icon-stylus:before {
  content: '\e9ed';
}
.icon-varnish-brush:before {
  content: '\e9ee';
}
.icon-vector-arc:before {
  content: '\e9ef';
}
.icon-vector-circle:before {
  content: '\e9f0';
}
.icon-vector-line:before {
  content: '\e9f1';
}
.icon-vector-path-1:before {
  content: '\e9f2';
}
.icon-vector-path-2:before {
  content: '\e9f3';
}
.icon-vector-path-3:before {
  content: '\e9f4';
}
.icon-vector-rectangle:before {
  content: '\e9f5';
}
.icon-vector-triangle:before {
  content: '\e9f6';
}
.icon-agenda-1:before {
  content: '\e9f7';
}
.icon-agenda-2:before {
  content: '\e9f8';
}
.icon-article-2:before {
  content: '\e9f9';
}
.icon-article-3:before {
  content: '\e9fa';
}
.icon-article:before {
  content: '\e9fb';
}
.icon-ballpen:before {
  content: '\e9fc';
}
.icon-bold:before {
  content: '\e9fd';
}
.icon-book-2:before {
  content: '\e9fe';
}
.icon-book-3:before {
  content: '\e9ff';
}
.icon-book-4:before {
  content: '\ea00';
}
.icon-book-5:before {
  content: '\ea01';
}
.icon-book-6:before {
  content: '\ea02';
}
.icon-book:before {
  content: '\ea03';
}
.icon-bookmark-1:before {
  content: '\ea04';
}
.icon-bookmark-2:before {
  content: '\ea05';
}
.icon-bookmark-3:before {
  content: '\ea06';
}
.icon-bookmark-add:before {
  content: '\ea07';
}
.icon-bookmark-checked:before {
  content: '\ea08';
}
.icon-bookmark:before {
  content: '\ea09';
}
.icon-bookmarks:before {
  content: '\ea0a';
}
.icon-character:before {
  content: '\ea0b';
}
.icon-characters:before {
  content: '\ea0c';
}
.icon-clipboard-1:before {
  content: '\ea0d';
}
.icon-clipboard-2:before {
  content: '\ea0e';
}
.icon-clipboard-checked:before {
  content: '\ea0f';
}
.icon-clipboard-file:before {
  content: '\ea10';
}
.icon-cmd:before {
  content: '\ea11';
}
.icon-content-1:before {
  content: '\ea12';
}
.icon-content-2:before {
  content: '\ea13';
}
.icon-content-3:before {
  content: '\ea14';
}
.icon-copy-plain-text:before {
  content: '\ea15';
}
.icon-copy-styles:before {
  content: '\ea16';
}
.icon-CV-2:before {
  content: '\ea17';
}
.icon-CV:before {
  content: '\ea18';
}
.icon-document-envelope-1:before {
  content: '\ea19';
}
.icon-document-envelope-2:before {
  content: '\ea1a';
}
.icon-document-pencil:before {
  content: '\ea1b';
}
.icon-indent-left:before {
  content: '\ea1c';
}
.icon-indent-right:before {
  content: '\ea1d';
}
.icon-liner:before {
  content: '\ea1e';
}
.icon-list-bullets:before {
  content: '\ea1f';
}
.icon-list-numbers:before {
  content: '\ea20';
}
.icon-marker:before {
  content: '\ea21';
}
.icon-newspaper:before {
  content: '\ea22';
}
.icon-nib-1:before {
  content: '\ea23';
}
.icon-nib-2:before {
  content: '\ea24';
}
.icon-note:before {
  content: '\ea25';
}
.icon-notebook:before {
  content: '\ea26';
}
.icon-office-archive:before {
  content: '\ea27';
}
.icon-paper-clamp:before {
  content: '\ea28';
}
.icon-papyrus:before {
  content: '\ea29';
}
.icon-paragraph-down:before {
  content: '\ea2a';
}
.icon-paragraph-up:before {
  content: '\ea2b';
}
.icon-paragraph:before {
  content: '\ea2c';
}
.icon-pen-1:before {
  content: '\ea2d';
}
.icon-pencil-1:before {
  content: '\ea2e';
}
.icon-pencil-2:before {
  content: '\ea2f';
}
.icon-quill-ink-pot:before {
  content: '\ea30';
}
.icon-quill:before {
  content: '\ea31';
}
.icon-quotes:before {
  content: '\ea32';
}
.icon-research:before {
  content: '\ea33';
}
.icon-spell-check:before {
  content: '\ea34';
}
.icon-strikethrough:before {
  content: '\ea35';
}
.icon-text-box:before {
  content: '\ea36';
}
.icon-text-color:before {
  content: '\ea37';
}
.icon-text-input:before {
  content: '\ea38';
}
.icon-text-italic:before {
  content: '\ea39';
}
.icon-text:before {
  content: '\ea3a';
}
.icon-translate:before {
  content: '\ea3b';
}
.icon-underline:before {
  content: '\ea3c';
}
.icon-user-manual-2:before {
  content: '\ea3d';
}
.icon-user-manual:before {
  content: '\ea3e';
}
.icon-write-2:before {
  content: '\ea3f';
}
.icon-write-3:before {
  content: '\ea40';
}
.icon-write-off:before {
  content: '\ea41';
}
.icon-write:before {
  content: '\ea42';
}
.icon-alcohol:before {
  content: '\ea43';
}
.icon-apple-1:before {
  content: '\ea44';
}
.icon-apple-2:before {
  content: '\ea45';
}
.icon-apple-3:before {
  content: '\ea46';
}
.icon-avocado:before {
  content: '\ea47';
}
.icon-banana:before {
  content: '\ea48';
}
.icon-barbecue:before {
  content: '\ea49';
}
.icon-beer-mug:before {
  content: '\ea4a';
}
.icon-beverage:before {
  content: '\ea4b';
}
.icon-blender:before {
  content: '\ea4c';
}
.icon-bottle-beer:before {
  content: '\ea4d';
}
.icon-bottle-milk:before {
  content: '\ea4e';
}
.icon-bottle-wine:before {
  content: '\ea4f';
}
.icon-bowl:before {
  content: '\ea50';
}
.icon-bread-1:before {
  content: '\ea51';
}
.icon-bread-2:before {
  content: '\ea52';
}
.icon-butcher-knife:before {
  content: '\ea53';
}
.icon-cake:before {
  content: '\ea54';
}
.icon-candy:before {
  content: '\ea55';
}
.icon-carrot:before {
  content: '\ea56';
}
.icon-champagne:before {
  content: '\ea57';
}
.icon-cheese:before {
  content: '\ea58';
}
.icon-chef-hat-1:before {
  content: '\ea59';
}
.icon-chef-hat-2:before {
  content: '\ea5a';
}
.icon-chef-knife:before {
  content: '\ea5b';
}
.icon-cherry:before {
  content: '\ea5c';
}
.icon-chicken:before {
  content: '\ea5d';
}
.icon-coconut:before {
  content: '\ea5e';
}
.icon-coffee-bean:before {
  content: '\ea5f';
}
.icon-coffee-cup:before {
  content: '\ea60';
}
.icon-coffee-machine:before {
  content: '\ea61';
}
.icon-coffee-mug:before {
  content: '\ea62';
}
.icon-cookie-1:before {
  content: '\ea63';
}
.icon-cookie-2:before {
  content: '\ea64';
}
.icon-cooking-pan:before {
  content: '\ea65';
}
.icon-cooking-pot:before {
  content: '\ea66';
}
.icon-cooking-timer-1:before {
  content: '\ea67';
}
.icon-cooking-timer-2:before {
  content: '\ea68';
}
.icon-cooking-timer-3:before {
  content: '\ea69';
}
.icon-cooking-timer-4:before {
  content: '\ea6a';
}
.icon-cooking-timer-5:before {
  content: '\ea6b';
}
.icon-cooking-timer-6:before {
  content: '\ea6c';
}
.icon-cooking-timer-7:before {
  content: '\ea6d';
}
.icon-cooking-timer-8:before {
  content: '\ea6e';
}
.icon-corkscrew:before {
  content: '\ea6f';
}
.icon-croissant:before {
  content: '\ea70';
}
.icon-cupcake:before {
  content: '\ea71';
}
.icon-egg:before {
  content: '\ea72';
}
.icon-fast-food:before {
  content: '\ea73';
}
.icon-fire:before {
  content: '\ea74';
}
.icon-fork-knife-1:before {
  content: '\ea75';
}
.icon-fork-knife-2:before {
  content: '\ea76';
}
.icon-fork-spoon-knife:before {
  content: '\ea77';
}
.icon-fork-spoon:before {
  content: '\ea78';
}
.icon-fork:before {
  content: '\ea79';
}
.icon-fridge:before {
  content: '\ea7a';
}
.icon-fried-egg:before {
  content: '\ea7b';
}
.icon-fries:before {
  content: '\ea7c';
}
.icon-glass-beer-1:before {
  content: '\ea7d';
}
.icon-glass-beer-2:before {
  content: '\ea7e';
}
.icon-glass-champagme-1:before {
  content: '\ea7f';
}
.icon-glass-champagme-2:before {
  content: '\ea80';
}
.icon-glass-cocktail-1:before {
  content: '\ea81';
}
.icon-glass-cocktail-2:before {
  content: '\ea82';
}
.icon-glass-water:before {
  content: '\ea83';
}
.icon-glass-wine-1:before {
  content: '\ea84';
}
.icon-glass-wine-2:before {
  content: '\ea85';
}
.icon-glass-wine-3:before {
  content: '\ea86';
}
.icon-grapes:before {
  content: '\ea87';
}
.icon-grider:before {
  content: '\ea88';
}
.icon-hamburger:before {
  content: '\ea89';
}
.icon-ice-cream-1:before {
  content: '\ea8a';
}
.icon-ice-cream-2:before {
  content: '\ea8b';
}
.icon-ice-cream-3:before {
  content: '\ea8c';
}
.icon-jam-jar:before {
  content: '\ea8d';
}
.icon-kitchen-glove:before {
  content: '\ea8e';
}
.icon-kitchen-scale:before {
  content: '\ea8f';
}
.icon-knife:before {
  content: '\ea90';
}
.icon-ladle:before {
  content: '\ea91';
}
.icon-lemon:before {
  content: '\ea92';
}
.icon-lollipop-1:before {
  content: '\ea93';
}
.icon-lollipop-2:before {
  content: '\ea94';
}
.icon-meal-time:before {
  content: '\ea95';
}
.icon-meal:before {
  content: '\ea96';
}
.icon-microwave:before {
  content: '\ea97';
}
.icon-mushroom:before {
  content: '\ea98';
}
.icon-pear-1:before {
  content: '\ea99';
}
.icon-pear-2:before {
  content: '\ea9a';
}
.icon-pear-apple:before {
  content: '\ea9b';
}
.icon-pepper:before {
  content: '\ea9c';
}
.icon-pitcher:before {
  content: '\ea9d';
}
.icon-pizza:before {
  content: '\ea9e';
}
.icon-pretzel:before {
  content: '\ea9f';
}
.icon-recipe:before {
  content: '\eaa0';
}
.icon-sausage:before {
  content: '\eaa1';
}
.icon-shake:before {
  content: '\eaa2';
}
.icon-skewer:before {
  content: '\eaa3';
}
.icon-spoon:before {
  content: '\eaa4';
}
.icon-strawberry:before {
  content: '\eaa5';
}
.icon-sushi-1:before {
  content: '\eaa6';
}
.icon-sushi-2:before {
  content: '\eaa7';
}
.icon-tea-cup:before {
  content: '\eaa8';
}
.icon-tea-mug:before {
  content: '\eaa9';
}
.icon-teapot-1:before {
  content: '\eaaa';
}
.icon-teapot-2:before {
  content: '\eaab';
}
.icon-togo-cup-1:before {
  content: '\eaac';
}
.icon-water-can:before {
  content: '\eaad';
}
.icon-watermelon:before {
  content: '\eaae';
}
.icon-airplay:before {
  content: '\eaaf';
}
.icon-antena-1:before {
  content: '\eab0';
}
.icon-antena-2:before {
  content: '\eab1';
}
.icon-antena-3:before {
  content: '\eab2';
}
.icon-bluetooth:before {
  content: '\eab3';
}
.icon-broadcast:before {
  content: '\eab4';
}
.icon-cloud-backup:before {
  content: '\eab5';
}
.icon-cloud-check:before {
  content: '\eab6';
}
.icon-cloud-download:before {
  content: '\eab7';
}
.icon-cloud-edit:before {
  content: '\eab8';
}
.icon-cloud-error-2:before {
  content: '\eab9';
}
.icon-cloud-error:before {
  content: '\eaba';
}
.icon-cloud-help:before {
  content: '\eabb';
}
.icon-cloud-hosting:before {
  content: '\eabc';
}
.icon-cloud-locked:before {
  content: '\eabd';
}
.icon-cloud-minus:before {
  content: '\eabe';
}
.icon-cloud-music:before {
  content: '\eabf';
}
.icon-cloud-off:before {
  content: '\eac0';
}
.icon-cloud-plus:before {
  content: '\eac1';
}
.icon-cloud-search:before {
  content: '\eac2';
}
.icon-cloud-settings:before {
  content: '\eac3';
}
.icon-cloud-share:before {
  content: '\eac4';
}
.icon-cloud-sync:before {
  content: '\eac5';
}
.icon-cloud-upload:before {
  content: '\eac6';
}
.icon-cloud:before {
  content: '\eac7';
}
.icon-database-backup:before {
  content: '\eac8';
}
.icon-database-check:before {
  content: '\eac9';
}
.icon-database-edit:before {
  content: '\eaca';
}
.icon-database-error:before {
  content: '\eacb';
}
.icon-database-firewall:before {
  content: '\eacc';
}
.icon-database-locked:before {
  content: '\eacd';
}
.icon-database-plus:before {
  content: '\eace';
}
.icon-database-refresh:before {
  content: '\eacf';
}
.icon-database-remove:before {
  content: '\ead0';
}
.icon-database-settings:before {
  content: '\ead1';
}
.icon-database:before {
  content: '\ead2';
}
.icon-datatbase-serach:before {
  content: '\ead3';
}
.icon-internet-block:before {
  content: '\ead4';
}
.icon-internet-location:before {
  content: '\ead5';
}
.icon-internet-lock:before {
  content: '\ead6';
}
.icon-internet-refresh:before {
  content: '\ead7';
}
.icon-internet-search:before {
  content: '\ead8';
}
.icon-internet-settings:before {
  content: '\ead9';
}
.icon-internet-time:before {
  content: '\eada';
}
.icon-internet:before {
  content: '\eadb';
}
.icon-mobile-hotspot:before {
  content: '\eadc';
}
.icon-network-desktop:before {
  content: '\eadd';
}
.icon-network-laptop:before {
  content: '\eade';
}
.icon-network-smartphone:before {
  content: '\eadf';
}
.icon-network:before {
  content: '\eae0';
}
.icon-satelite-signal:before {
  content: '\eae1';
}
.icon-satelite:before {
  content: '\eae2';
}
.icon-server-backup:before {
  content: '\eae3';
}
.icon-server-check:before {
  content: '\eae4';
}
.icon-server-edit:before {
  content: '\eae5';
}
.icon-server-error:before {
  content: '\eae6';
}
.icon-server-firewall:before {
  content: '\eae7';
}
.icon-server-locked:before {
  content: '\eae8';
}
.icon-server-plus:before {
  content: '\eae9';
}
.icon-server-refresh:before {
  content: '\eaea';
}
.icon-server-remove:before {
  content: '\eaeb';
}
.icon-server-search:before {
  content: '\eaec';
}
.icon-server-settings:before {
  content: '\eaed';
}
.icon-server:before {
  content: '\eaee';
}
.icon-signal-1:before {
  content: '\eaef';
}
.icon-signal-2:before {
  content: '\eaf0';
}
.icon-signal-4:before {
  content: '\eaf1';
}
.icon-usb-1:before {
  content: '\eaf2';
}
.icon-usb-2:before {
  content: '\eaf3';
}
.icon-wifi-locked:before {
  content: '\eaf4';
}
.icon-wifi-tethering-off:before {
  content: '\eaf5';
}
.icon-wifi-tethering:before {
  content: '\eaf6';
}
.icon-wifi:before {
  content: '\eaf7';
}
.icon-add-tab:before {
  content: '\eaf8';
}
.icon-airplane-mode-off:before {
  content: '\eaf9';
}
.icon-airplane-mode:before {
  content: '\eafa';
}
.icon-align-bottom:before {
  content: '\eafb';
}
.icon-align-left:before {
  content: '\eafc';
}
.icon-align-right:before {
  content: '\eafd';
}
.icon-align-top:before {
  content: '\eafe';
}
.icon-backward:before {
  content: '\eaff';
}
.icon-ban:before {
  content: '\eb00';
}
.icon-brightness-high:before {
  content: '\eb01';
}
.icon-brightness-low:before {
  content: '\eb02';
}
.icon-cancel-circle:before {
  content: '\eb03';
}
.icon-cancel-square-2:before {
  content: '\eb04';
}
.icon-cancel-square:before {
  content: '\eb05';
}
.icon-check-all:before {
  content: '\eb06';
}
.icon-check-circle-2:before {
  content: '\eb07';
}
.icon-check-circle:before {
  content: '\eb08';
}
.icon-check-square-2:before {
  content: '\eb09';
}
.icon-check-square:before {
  content: '\eb0a';
}
.icon-check:before {
  content: '\eb0b';
}
.icon-close:before {
  content: '\eb0c';
}
.icon-config-1:before {
  content: '\eb0d';
}
.icon-config-2:before {
  content: '\eb0e';
}
.icon-contract-2:before {
  content: '\eb0f';
}
.icon-contract-3:before {
  content: '\eb10';
}
.icon-contract-4:before {
  content: '\eb11';
}
.icon-contract:before {
  content: '\eb12';
}
.icon-cursor-click:before {
  content: '\eb13';
}
.icon-cursor-double-click:before {
  content: '\eb14';
}
.icon-cursor-select:before {
  content: '\eb15';
}
.icon-cursor:before {
  content: '\eb16';
}
.icon-door-lock:before {
  content: '\eb17';
}
.icon-double-tap:before {
  content: '\eb18';
}
.icon-download-1:before {
  content: '\eb19';
}
.icon-download-2:before {
  content: '\eb1a';
}
.icon-drag-1:before {
  content: '\eb1b';
}
.icon-drag:before {
  content: '\eb1c';
}
.icon-edit-1:before {
  content: '\eb1d';
}
.icon-edit-2:before {
  content: '\eb1e';
}
.icon-edit-3:before {
  content: '\eb1f';
}
.icon-expand-2:before {
  content: '\eb20';
}
.icon-expand-3:before {
  content: '\eb21';
}
.icon-expand-4:before {
  content: '\eb22';
}
.icon-expand-horizontal:before {
  content: '\eb23';
}
.icon-expand-vertical:before {
  content: '\eb24';
}
.icon-expand:before {
  content: '\eb25';
}
.icon-eye-off:before {
  content: '\eb26';
}
.icon-eye:before {
  content: '\eb27';
}
.icon-fingerprint:before {
  content: '\eb28';
}
.icon-flash-2:before {
  content: '\eb29';
}
.icon-flash-3:before {
  content: '\eb2a';
}
.icon-flash-4:before {
  content: '\eb2b';
}
.icon-flip-horizontal:before {
  content: '\eb2c';
}
.icon-flip-vertical:before {
  content: '\eb2d';
}
.icon-forward:before {
  content: '\eb2e';
}
.icon-grid-circles:before {
  content: '\eb2f';
}
.icon-grid-squares-2:before {
  content: '\eb30';
}
.icon-grid-squares:before {
  content: '\eb31';
}
.icon-hamburger-menu-1:before {
  content: '\eb32';
}
.icon-hamburger-menu-2:before {
  content: '\eb33';
}
.icon-hand:before {
  content: '\eb34';
}
.icon-help-1:before {
  content: '\eb35';
}
.icon-help-2:before {
  content: '\eb36';
}
.icon-home:before {
  content: '\eb37';
}
.icon-info:before {
  content: '\eb38';
}
.icon-inside:before {
  content: '\eb39';
}
.icon-key-1:before {
  content: '\eb3a';
}
.icon-key-2:before {
  content: '\eb3b';
}
.icon-label-cancel:before {
  content: '\eb3c';
}
.icon-label:before {
  content: '\eb3d';
}
.icon-layout-1:before {
  content: '\eb3e';
}
.icon-layout-2:before {
  content: '\eb3f';
}
.icon-layout-3:before {
  content: '\eb40';
}
.icon-list-1:before {
  content: '\eb41';
}
.icon-list-2:before {
  content: '\eb42';
}
.icon-list-3:before {
  content: '\eb43';
}
.icon-list-4:before {
  content: '\eb44';
}
.icon-lock:before {
  content: '\eb45';
}
.icon-loop:before {
  content: '\eb46';
}
.icon-magic-wand-1:before {
  content: '\eb47';
}
.icon-magic-wand-2:before {
  content: '\eb48';
}
.icon-magnet:before {
  content: '\eb49';
}
.icon-magnifier-1:before {
  content: '\eb4a';
}
.icon-magnifier-2:before {
  content: '\eb4b';
}
.icon-maximize-left:before {
  content: '\eb4c';
}
.icon-maximize-right:before {
  content: '\eb4d';
}
.icon-menu-circle-grid:before {
  content: '\eb4e';
}
.icon-minus-circle:before {
  content: '\eb4f';
}
.icon-minus-square:before {
  content: '\eb50';
}
.icon-more-circle:before {
  content: '\eb51';
}
.icon-more-circles-horizontal:before {
  content: '\eb52';
}
.icon-more-circles-vertical:before {
  content: '\eb53';
}
.icon-more-squares-vertical-filled:before {
  content: '\eb54';
}
.icon-more-squares-vertical:before {
  content: '\eb55';
}
.icon-notificatio-off:before {
  content: '\eb56';
}
.icon-notification-2:before {
  content: '\eb57';
}
.icon-notification-add:before {
  content: '\eb58';
}
.icon-notification-paused:before {
  content: '\eb59';
}
.icon-notification:before {
  content: '\eb5a';
}
.icon-outside:before {
  content: '\eb5b';
}
.icon-paper-clip:before {
  content: '\eb5c';
}
.icon-paper-plane:before {
  content: '\eb5d';
}
.icon-pass:before {
  content: '\eb5e';
}
.icon-phone-shake:before {
  content: '\eb5f';
}
.icon-pin-1:before {
  content: '\eb60';
}
.icon-pin-2:before {
  content: '\eb61';
}
.icon-pin-3:before {
  content: '\eb62';
}
.icon-pin-code:before {
  content: '\eb63';
}
.icon-plus-circle:before {
  content: '\eb64';
}
.icon-plus-square:before {
  content: '\eb65';
}
.icon-plus:before {
  content: '\eb66';
}
.icon-pointer:before {
  content: '\eb67';
}
.icon-power:before {
  content: '\eb68';
}
.icon-press:before {
  content: '\eb69';
}
.icon-question:before {
  content: '\eb6a';
}
.icon-refresh-2:before {
  content: '\eb6b';
}
.icon-refresh-warning:before {
  content: '\eb6c';
}
.icon-refresh:before {
  content: '\eb6d';
}
.icon-reload-checked:before {
  content: '\eb6e';
}
.icon-reload:before {
  content: '\eb6f';
}
.icon-remove-tab:before {
  content: '\eb70';
}
.icon-rotate:before {
  content: '\eb71';
}
.icon-scroll:before {
  content: '\eb72';
}
.icon-search-history:before {
  content: '\eb73';
}
.icon-settings-1:before {
  content: '\eb74';
}
.icon-settings-2:before {
  content: '\eb75';
}
.icon-share-1:before {
  content: '\eb76';
}
.icon-share-2:before {
  content: '\eb77';
}
.icon-share-3:before {
  content: '\eb78';
}
.icon-share-4:before {
  content: '\eb79';
}
.icon-spread:before {
  content: '\eb7a';
}
.icon-swap-horizontal:before {
  content: '\eb7b';
}
.icon-swap-vertical:before {
  content: '\eb7c';
}
.icon-swipe-down:before {
  content: '\eb7d';
}
.icon-swipe-left:before {
  content: '\eb7e';
}
.icon-swipe-right:before {
  content: '\eb7f';
}
.icon-swipe-up:before {
  content: '\eb80';
}
.icon-switch-off:before {
  content: '\eb81';
}
.icon-switch-on:before {
  content: '\eb82';
}
.icon-switches-1:before {
  content: '\eb83';
}
.icon-switches-2:before {
  content: '\eb84';
}
.icon-tabs-2:before {
  content: '\eb85';
}
.icon-tabs:before {
  content: '\eb86';
}
.icon-tap:before {
  content: '\eb87';
}
.icon-touch:before {
  content: '\eb88';
}
.icon-trash-recycle:before {
  content: '\eb89';
}
.icon-trash:before {
  content: '\eb8a';
}
.icon-unlocked:before {
  content: '\eb8b';
}
.icon-upload-1:before {
  content: '\eb8c';
}
.icon-upload-2:before {
  content: '\eb8d';
}
.icon-warning-circle:before {
  content: '\eb8e';
}
.icon-warning-hexagon:before {
  content: '\eb8f';
}
.icon-warning-triangle:before {
  content: '\eb90';
}
.icon-zoom-in-1:before {
  content: '\eb91';
}
.icon-zoom-in-2:before {
  content: '\eb92';
}
.icon-zoom-out-1:before {
  content: '\eb93';
}
.icon-zoom-out-2:before {
  content: '\eb94';
}
.icon-file-archive:before {
  content: '\eb95';
}
.icon-file-audio:before {
  content: '\eb96';
}
.icon-file-backup:before {
  content: '\eb97';
}
.icon-file-bookmark:before {
  content: '\eb98';
}
.icon-file-clip:before {
  content: '\eb99';
}
.icon-file-code:before {
  content: '\eb9a';
}
.icon-file-copy:before {
  content: '\eb9b';
}
.icon-file-corrupted:before {
  content: '\eb9c';
}
.icon-file-delete:before {
  content: '\eb9d';
}
.icon-file-download:before {
  content: '\eb9e';
}
.icon-file-edit:before {
  content: '\eb9f';
}
.icon-file-error:before {
  content: '\eba0';
}
.icon-file-exchange:before {
  content: '\eba1';
}
.icon-file-export:before {
  content: '\eba2';
}
.icon-file-image:before {
  content: '\eba3';
}
.icon-file-import:before {
  content: '\eba4';
}
.icon-file-info:before {
  content: '\eba5';
}
.icon-file-linked:before {
  content: '\eba6';
}
.icon-file-load:before {
  content: '\eba7';
}
.icon-file-locked:before {
  content: '\eba8';
}
.icon-file-new:before {
  content: '\eba9';
}
.icon-file-remove:before {
  content: '\ebaa';
}
.icon-file-search:before {
  content: '\ebab';
}
.icon-file-settings:before {
  content: '\ebac';
}
.icon-file-share:before {
  content: '\ebad';
}
.icon-file-star:before {
  content: '\ebae';
}
.icon-file-sync:before {
  content: '\ebaf';
}
.icon-file-table:before {
  content: '\ebb0';
}
.icon-file-text:before {
  content: '\ebb1';
}
.icon-file-upload:before {
  content: '\ebb2';
}
.icon-file-vector:before {
  content: '\ebb3';
}
.icon-file-video:before {
  content: '\ebb4';
}
.icon-file-warning:before {
  content: '\ebb5';
}
.icon-file:before {
  content: '\ebb6';
}
.icon-files-2:before {
  content: '\ebb7';
}
.icon-files:before {
  content: '\ebb8';
}
.icon-folder-archive:before {
  content: '\ebb9';
}
.icon-folder-audio:before {
  content: '\ebba';
}
.icon-folder-backup:before {
  content: '\ebbb';
}
.icon-folder-bookmark:before {
  content: '\ebbc';
}
.icon-folder-check:before {
  content: '\ebbd';
}
.icon-folder-code:before {
  content: '\ebbe';
}
.icon-folder-copy:before {
  content: '\ebbf';
}
.icon-folder-delete:before {
  content: '\ebc0';
}
.icon-folder-download:before {
  content: '\ebc1';
}
.icon-folder-exchange:before {
  content: '\ebc2';
}
.icon-folder-export:before {
  content: '\ebc3';
}
.icon-folder-file:before {
  content: '\ebc4';
}
.icon-folder-image:before {
  content: '\ebc5';
}
.icon-folder-import:before {
  content: '\ebc6';
}
.icon-folder-info:before {
  content: '\ebc7';
}
.icon-folder-linked:before {
  content: '\ebc8';
}
.icon-folder-locked:before {
  content: '\ebc9';
}
.icon-folder-new:before {
  content: '\ebca';
}
.icon-folder-open:before {
  content: '\ebcb';
}
.icon-folder-search:before {
  content: '\ebcc';
}
.icon-folder-share:before {
  content: '\ebcd';
}
.icon-folder-star:before {
  content: '\ebce';
}
.icon-folder-sync:before {
  content: '\ebcf';
}
.icon-folder-text:before {
  content: '\ebd0';
}
.icon-folder-upload:before {
  content: '\ebd1';
}
.icon-folder-video:before {
  content: '\ebd2';
}
.icon-folder-warning:before {
  content: '\ebd3';
}
.icon-folder:before {
  content: '\ebd4';
}
.icon-K:before {
  content: '\ebd5';
}
.icon-album-2:before {
  content: '\ebd6';
}
.icon-album:before {
  content: '\ebd7';
}
.icon-albums:before {
  content: '\ebd8';
}
.icon-aperture:before {
  content: '\ebd9';
}
.icon-aspect-ratio:before {
  content: '\ebda';
}
.icon-audiobook-2:before {
  content: '\ebdb';
}
.icon-audiobook:before {
  content: '\ebdc';
}
.icon-boombox-1:before {
  content: '\ebdd';
}
.icon-boombox-2:before {
  content: '\ebde';
}
.icon-camcorder:before {
  content: '\ebdf';
}
.icon-camera-focus:before {
  content: '\ebe0';
}
.icon-camera-off:before {
  content: '\ebe1';
}
.icon-camera-reverse:before {
  content: '\ebe2';
}
.icon-camera-swap:before {
  content: '\ebe3';
}
.icon-camera-tripod:before {
  content: '\ebe4';
}
.icon-camera:before {
  content: '\ebe5';
}
.icon-cassette:before {
  content: '\ebe6';
}
.icon-CD:before {
  content: '\ebe7';
}
.icon-clapperboard:before {
  content: '\ebe8';
}
.icon-closed-caption:before {
  content: '\ebe9';
}
.icon-director-chair:before {
  content: '\ebea';
}
.icon-earphones-1:before {
  content: '\ebeb';
}
.icon-earphones-2:before {
  content: '\ebec';
}
.icon-earphones-3:before {
  content: '\ebed';
}
.icon-eject-circle:before {
  content: '\ebee';
}
.icon-eject:before {
  content: '\ebef';
}
.icon-end-circle:before {
  content: '\ebf0';
}
.icon-end:before {
  content: '\ebf1';
}
.icon-exposure:before {
  content: '\ebf2';
}
.icon-extrenal-flash:before {
  content: '\ebf3';
}
.icon-film-1:before {
  content: '\ebf4';
}
.icon-film-2:before {
  content: '\ebf5';
}
.icon-film-reel:before {
  content: '\ebf6';
}
.icon-flash-auto:before {
  content: '\ebf7';
}
.icon-flash-off:before {
  content: '\ebf8';
}
.icon-flsh:before {
  content: '\ebf9';
}
.icon-forward-circle:before {
  content: '\ebfa';
}
.icon-forward1:before {
  content: '\ebfb';
}
.icon-frame:before {
  content: '\ebfc';
}
.icon-HD:before {
  content: '\ebfd';
}
.icon-headphones-1:before {
  content: '\ebfe';
}
.icon-headphones-2:before {
  content: '\ebff';
}
.icon-loop-all:before {
  content: '\ec00';
}
.icon-loop2:before {
  content: '\ec01';
}
.icon-loop1:before {
  content: '\ec02';
}
.icon-macro:before {
  content: '\ec03';
}
.icon-media-player:before {
  content: '\ec04';
}
.icon-mic-2:before {
  content: '\ec05';
}
.icon-microphone-off:before {
  content: '\ec06';
}
.icon-microphone:before {
  content: '\ec07';
}
.icon-movie-camera:before {
  content: '\ec08';
}
.icon-music-tone-1-off:before {
  content: '\ec09';
}
.icon-music-tone-1:before {
  content: '\ec0a';
}
.icon-music-tone-2-off:before {
  content: '\ec0b';
}
.icon-music-tone-2:before {
  content: '\ec0c';
}
.icon-mute:before {
  content: '\ec0d';
}
.icon-panorama:before {
  content: '\ec0e';
}
.icon-pause-circle:before {
  content: '\ec0f';
}
.icon-pause:before {
  content: '\ec10';
}
.icon-photo-add:before {
  content: '\ec11';
}
.icon-photo-album:before {
  content: '\ec12';
}
.icon-photo:before {
  content: '\ec13';
}
.icon-photos:before {
  content: '\ec14';
}
.icon-play-circle:before {
  content: '\ec15';
}
.icon-play:before {
  content: '\ec16';
}
.icon-playlist-1:before {
  content: '\ec17';
}
.icon-playlist-add:before {
  content: '\ec18';
}
.icon-playlist-audio:before {
  content: '\ec19';
}
.icon-playlist-video:before {
  content: '\ec1a';
}
.icon-podcast:before {
  content: '\ec1b';
}
.icon-rec-circle:before {
  content: '\ec1c';
}
.icon-retro-camera:before {
  content: '\ec1d';
}
.icon-rewind-circle:before {
  content: '\ec1e';
}
.icon-rewind:before {
  content: '\ec1f';
}
.icon-rotate-left:before {
  content: '\ec20';
}
.icon-rotate-right:before {
  content: '\ec21';
}
.icon-SD:before {
  content: '\ec22';
}
.icon-shuffle:before {
  content: '\ec23';
}
.icon-slideshow-1:before {
  content: '\ec24';
}
.icon-slideshow-2:before {
  content: '\ec25';
}
.icon-soundwave:before {
  content: '\ec26';
}
.icon-speaker-1:before {
  content: '\ec27';
}
.icon-speaker-2:before {
  content: '\ec28';
}
.icon-start-circle:before {
  content: '\ec29';
}
.icon-start:before {
  content: '\ec2a';
}
.icon-stereo-1:before {
  content: '\ec2b';
}
.icon-stereo2:before {
  content: '\ec2c';
}
.icon-stop-circle:before {
  content: '\ec2d';
}
.icon-stop:before {
  content: '\ec2e';
}
.icon-turntable:before {
  content: '\ec2f';
}
.icon-video-camera-2:before {
  content: '\ec30';
}
.icon-video-camera-off:before {
  content: '\ec31';
}
.icon-video-camera:before {
  content: '\ec32';
}
.icon-volume-1:before {
  content: '\ec33';
}
.icon-volume-2:before {
  content: '\ec34';
}
.icon-volume-off:before {
  content: '\ec35';
}
.icon-vumeter:before {
  content: '\ec36';
}
.icon-D-glasses:before {
  content: '\ec37';
}
.icon-armchair:before {
  content: '\ec38';
}
.icon-balloons:before {
  content: '\ec39';
}
.icon-baseball-1:before {
  content: '\ec3a';
}
.icon-baseball-2:before {
  content: '\ec3b';
}
.icon-basketball-2:before {
  content: '\ec3c';
}
.icon-basketball:before {
  content: '\ec3d';
}
.icon-binoculars:before {
  content: '\ec3e';
}
.icon-bow-arrow:before {
  content: '\ec3f';
}
.icon-bowling-1:before {
  content: '\ec40';
}
.icon-bowling-2:before {
  content: '\ec41';
}
.icon-chess-1:before {
  content: '\ec42';
}
.icon-chess-2:before {
  content: '\ec43';
}
.icon-couch:before {
  content: '\ec44';
}
.icon-cutter:before {
  content: '\ec45';
}
.icon-diamond-1:before {
  content: '\ec46';
}
.icon-diamond-2:before {
  content: '\ec47';
}
.icon-diamond-ring:before {
  content: '\ec48';
}
.icon-do-not-disturb:before {
  content: '\ec49';
}
.icon-dress:before {
  content: '\ec4a';
}
.icon-duck-toy:before {
  content: '\ec4b';
}
.icon-fireworks:before {
  content: '\ec4c';
}
.icon-fishing:before {
  content: '\ec4d';
}
.icon-fitness:before {
  content: '\ec4e';
}
.icon-flashlight:before {
  content: '\ec4f';
}
.icon-football:before {
  content: '\ec50';
}
.icon-funnel:before {
  content: '\ec51';
}
.icon-gift:before {
  content: '\ec52';
}
.icon-golf:before {
  content: '\ec53';
}
.icon-guitar:before {
  content: '\ec54';
}
.icon-hammer:before {
  content: '\ec55';
}
.icon-hanger-1:before {
  content: '\ec56';
}
.icon-hanger-2:before {
  content: '\ec57';
}
.icon-hat-1:before {
  content: '\ec58';
}
.icon-hat-2:before {
  content: '\ec59';
}
.icon-hipster-glasses:before {
  content: '\ec5a';
}
.icon-iron:before {
  content: '\ec5b';
}
.icon-kg:before {
  content: '\ec5c';
}
.icon-kite:before {
  content: '\ec5d';
}
.icon-lamp-1:before {
  content: '\ec5e';
}
.icon-lamp-2:before {
  content: '\ec5f';
}
.icon-lego-1:before {
  content: '\ec60';
}
.icon-lego-2:before {
  content: '\ec61';
}
.icon-magic-wand-12:before {
  content: '\ec62';
}
.icon-magic-wand-22:before {
  content: '\ec63';
}
.icon-origami-1:before {
  content: '\ec64';
}
.icon-origami-2:before {
  content: '\ec65';
}
.icon-pants:before {
  content: '\ec66';
}
.icon-pingpong:before {
  content: '\ec67';
}
.icon-pool:before {
  content: '\ec68';
}
.icon-puzzle:before {
  content: '\ec69';
}
.icon-razor:before {
  content: '\ec6a';
}
.icon-ribbowbow:before {
  content: '\ec6b';
}
.icon-safety-pin:before {
  content: '\ec6c';
}
.icon-saw:before {
  content: '\ec6d';
}
.icon-screwdriver:before {
  content: '\ec6e';
}
.icon-scuba:before {
  content: '\ec6f';
}
.icon-shirt:before {
  content: '\ec70';
}
.icon-shoes:before {
  content: '\ec71';
}
.icon-shovel:before {
  content: '\ec72';
}
.icon-soccer-shoe:before {
  content: '\ec73';
}
.icon-soccer:before {
  content: '\ec74';
}
.icon-swimsuit:before {
  content: '\ec75';
}
.icon-swiss-knife:before {
  content: '\ec76';
}
.icon-t-shirt:before {
  content: '\ec77';
}
.icon-umbrella-open:before {
  content: '\ec78';
}
.icon-underwear:before {
  content: '\ec79';
}
.icon-volleyball:before {
  content: '\ec7a';
}
.icon-watering-can:before {
  content: '\ec7b';
}
.icon-wedding-rings:before {
  content: '\ec7c';
}
.icon-whistle:before {
  content: '\ec7d';
}
.icon-wrench-1:before {
  content: '\ec7e';
}
.icon-wrench-2:before {
  content: '\ec7f';
}
.icon-wrench-3:before {
  content: '\ec80';
}
.icon-wrench-hammer:before {
  content: '\ec81';
}
.icon-wrench-screwdriver-1:before {
  content: '\ec82';
}
.icon-wrench-screwdriver-2:before {
  content: '\ec83';
}
.icon-gag:before {
  content: '\ec84';
}
.icon-px:before {
  content: '\ec85';
}
.icon-after-effects:before {
  content: '\ec86';
}
.icon-aim:before {
  content: '\ec87';
}
.icon-airbnb:before {
  content: '\ec88';
}
.icon-amazon:before {
  content: '\ec89';
}
.icon-android:before {
  content: '\ec8a';
}
.icon-apple:before {
  content: '\ec8b';
}
.icon-audition:before {
  content: '\ec8c';
}
.icon-bebo:before {
  content: '\ec8d';
}
.icon-behance:before {
  content: '\ec8e';
}
.icon-blogger:before {
  content: '\ec8f';
}
.icon-bridge:before {
  content: '\ec90';
}
.icon-chrome:before {
  content: '\ec91';
}
.icon-codepen:before {
  content: '\ec92';
}
.icon-creative-market:before {
  content: '\ec93';
}
.icon-creativecloud:before {
  content: '\ec94';
}
.icon-delicious:before {
  content: '\ec95';
}
.icon-deviantart:before {
  content: '\ec96';
}
.icon-digg:before {
  content: '\ec97';
}
.icon-dreamweaver:before {
  content: '\ec98';
}
.icon-dribbble:before {
  content: '\ec99';
}
.icon-drive:before {
  content: '\ec9a';
}
.icon-dropbox:before {
  content: '\ec9b';
}
.icon-envato:before {
  content: '\ec9c';
}
.icon-facebook-messanger:before {
  content: '\ec9d';
}
.icon-facebook:before {
  content: '\ec9e';
}
.icon-finder:before {
  content: '\ec9f';
}
.icon-firefox:before {
  content: '\eca0';
}
.icon-flash:before {
  content: '\eca1';
}
.icon-flicr:before {
  content: '\eca2';
}
.icon-forrst:before {
  content: '\eca3';
}
.icon-foursquare:before {
  content: '\eca4';
}
.icon-git:before {
  content: '\eca5';
}
.icon-google-play-1:before {
  content: '\eca6';
}
.icon-google-play-2:before {
  content: '\eca7';
}
.icon-google-plus:before {
  content: '\eca8';
}
.icon-hangouts:before {
  content: '\eca9';
}
.icon-illustrator:before {
  content: '\ecaa';
}
.icon-inbox2:before {
  content: '\ecab';
}
.icon-indesign:before {
  content: '\ecac';
}
.icon-inspect:before {
  content: '\ecad';
}
.icon-instagram:before {
  content: '\ecae';
}
.icon-kickstarter:before {
  content: '\ecaf';
}
.icon-lastfm:before {
  content: '\ecb0';
}
.icon-linkedin:before {
  content: '\ecb1';
}
.icon-opera:before {
  content: '\ecb2';
}
.icon-osx:before {
  content: '\ecb3';
}
.icon-paypal:before {
  content: '\ecb4';
}
.icon-photoshop:before {
  content: '\ecb5';
}
.icon-picasa:before {
  content: '\ecb6';
}
.icon-pinterest:before {
  content: '\ecb7';
}
.icon-prelude:before {
  content: '\ecb8';
}
.icon-premiere-pro:before {
  content: '\ecb9';
}
.icon-rdio:before {
  content: '\ecba';
}
.icon-reddit:before {
  content: '\ecbb';
}
.icon-rss:before {
  content: '\ecbc';
}
.icon-safari:before {
  content: '\ecbd';
}
.icon-skype:before {
  content: '\ecbe';
}
.icon-soundcloud:before {
  content: '\ecbf';
}
.icon-spotify:before {
  content: '\ecc0';
}
.icon-squarespace:before {
  content: '\ecc1';
}
.icon-stumble-upon:before {
  content: '\ecc2';
}
.icon-tumblr:before {
  content: '\ecc3';
}
.icon-twitter:before {
  content: '\ecc4';
}
.icon-vimeo-1:before {
  content: '\ecc5';
}
.icon-vimeo-2:before {
  content: '\ecc6';
}
.icon-vk:before {
  content: '\ecc7';
}
.icon-whatsup:before {
  content: '\ecc8';
}
.icon-wikipedia:before {
  content: '\ecc9';
}
.icon-windows:before {
  content: '\ecca';
}
.icon-wordpress:before {
  content: '\eccb';
}
.icon-xing:before {
  content: '\eccc';
}
.icon-yahoo:before {
  content: '\eccd';
}
.icon-youtube:before {
  content: '\ecce';
}
.icon-zerply:before {
  content: '\eccf';
}
.icon-alarm-add:before {
  content: '\ecd0';
}
.icon-alarm-off:before {
  content: '\ecd1';
}
.icon-alarm-on:before {
  content: '\ecd2';
}
.icon-alram:before {
  content: '\ecd3';
}
.icon-calendar-2:before {
  content: '\ecd4';
}
.icon-calendar-check:before {
  content: '\ecd5';
}
.icon-calendar-date-2:before {
  content: '\ecd6';
}
.icon-calendar-date:before {
  content: '\ecd7';
}
.icon-calendar-time:before {
  content: '\ecd8';
}
.icon-calendar:before {
  content: '\ecd9';
}
.icon-clock-2:before {
  content: '\ecda';
}
.icon-clock:before {
  content: '\ecdb';
}
.icon-compass-1:before {
  content: '\ecdc';
}
.icon-compass-2:before {
  content: '\ecdd';
}
.icon-direction-1:before {
  content: '\ecde';
}
.icon-direction-2:before {
  content: '\ecdf';
}
.icon-direction:before {
  content: '\ece0';
}
.icon-distance-1:before {
  content: '\ece1';
}
.icon-distance-2:before {
  content: '\ece2';
}
.icon-fast-delivery:before {
  content: '\ece3';
}
.icon-gps-location:before {
  content: '\ece4';
}
.icon-history:before {
  content: '\ece5';
}
.icon-hourglass-1:before {
  content: '\ece6';
}
.icon-hourglass-2:before {
  content: '\ece7';
}
.icon-hourglass-reverse:before {
  content: '\ece8';
}
.icon-infinite-loop:before {
  content: '\ece9';
}
.icon-infinite:before {
  content: '\ecea';
}
.icon-location-1-off:before {
  content: '\eceb';
}
.icon-location-1-on:before {
  content: '\ecec';
}
.icon-location-1-search:before {
  content: '\eced';
}
.icon-location-2-add:before {
  content: '\ecee';
}
.icon-location-2-checked:before {
  content: '\ecef';
}
.icon-location-2-delete:before {
  content: '\ecf0';
}
.icon-location-2-off:before {
  content: '\ecf1';
}
.icon-location-2-remove:before {
  content: '\ecf2';
}
.icon-location-2:before {
  content: '\ecf3';
}
.icon-location-3:before {
  content: '\ecf4';
}
.icon-location-4:before {
  content: '\ecf5';
}
.icon-map-2:before {
  content: '\ecf6';
}
.icon-map-location-1:before {
  content: '\ecf7';
}
.icon-map-location-2:before {
  content: '\ecf8';
}
.icon-map-location-3:before {
  content: '\ecf9';
}
.icon-map-location-4:before {
  content: '\ecfa';
}
.icon-map-timezone:before {
  content: '\ecfb';
}
.icon-map:before {
  content: '\ecfc';
}
.icon-navigation-1:before {
  content: '\ecfd';
}
.icon-navigation-2:before {
  content: '\ecfe';
}
.icon-phone-location:before {
  content: '\ecff';
}
.icon-street-location:before {
  content: '\ed00';
}
.icon-street-view:before {
  content: '\ed01';
}
.icon-timer-1:before {
  content: '\ed02';
}
.icon-timer-2:before {
  content: '\ed03';
}
.icon-wind-direction:before {
  content: '\ed04';
}
.icon-wrist-watch:before {
  content: '\ed05';
}
.icon-anchor:before {
  content: '\ed06';
}
.icon-bicycle:before {
  content: '\ed07';
}
.icon-bicycling:before {
  content: '\ed08';
}
.icon-boat-1:before {
  content: '\ed09';
}
.icon-boat-2:before {
  content: '\ed0a';
}
.icon-bus-wifi:before {
  content: '\ed0b';
}
.icon-bus:before {
  content: '\ed0c';
}
.icon-cable-ski:before {
  content: '\ed0d';
}
.icon-car-2:before {
  content: '\ed0e';
}
.icon-car-battery:before {
  content: '\ed0f';
}
.icon-car-key:before {
  content: '\ed10';
}
.icon-car-parking:before {
  content: '\ed11';
}
.icon-car-service:before {
  content: '\ed12';
}
.icon-car-wash:before {
  content: '\ed13';
}
.icon-car-wifi:before {
  content: '\ed14';
}
.icon-car:before {
  content: '\ed15';
}
.icon-cog:before {
  content: '\ed16';
}
.icon-construction-barricade:before {
  content: '\ed17';
}
.icon-construction-cone:before {
  content: '\ed18';
}
.icon-directions:before {
  content: '\ed19';
}
.icon-elevator-1:before {
  content: '\ed1a';
}
.icon-elevator-2:before {
  content: '\ed1b';
}
.icon-escalator-down:before {
  content: '\ed1c';
}
.icon-escalator-up:before {
  content: '\ed1d';
}
.icon-flight-land:before {
  content: '\ed1e';
}
.icon-flight-takeoff:before {
  content: '\ed1f';
}
.icon-forklift:before {
  content: '\ed20';
}
.icon-fuel:before {
  content: '\ed21';
}
.icon-garage:before {
  content: '\ed22';
}
.icon-gas-station:before {
  content: '\ed23';
}
.icon-gearbox:before {
  content: '\ed24';
}
.icon-helicopter:before {
  content: '\ed25';
}
.icon-helmet-1:before {
  content: '\ed26';
}
.icon-helmet-2:before {
  content: '\ed27';
}
.icon-kids-scooter:before {
  content: '\ed28';
}
.icon-motorcycle:before {
  content: '\ed29';
}
.icon-off-roader:before {
  content: '\ed2a';
}
.icon-pickup-truck:before {
  content: '\ed2b';
}
.icon-racing-flag:before {
  content: '\ed2c';
}
.icon-road:before {
  content: '\ed2d';
}
.icon-rudder:before {
  content: '\ed2e';
}
.icon-scooter:before {
  content: '\ed2f';
}
.icon-ship:before {
  content: '\ed30';
}
.icon-speedometer:before {
  content: '\ed31';
}
.icon-stairs-down:before {
  content: '\ed32';
}
.icon-stairs-up:before {
  content: '\ed33';
}
.icon-supercar:before {
  content: '\ed34';
}
.icon-taxi-1:before {
  content: '\ed35';
}
.icon-taxi-2:before {
  content: '\ed36';
}
.icon-tractor:before {
  content: '\ed37';
}
.icon-traffic-light:before {
  content: '\ed38';
}
.icon-trailer:before {
  content: '\ed39';
}
.icon-train-1:before {
  content: '\ed3a';
}
.icon-train-2:before {
  content: '\ed3b';
}
.icon-train-wifi:before {
  content: '\ed3c';
}
.icon-tram:before {
  content: '\ed3d';
}
.icon-truck:before {
  content: '\ed3e';
}
.icon-van:before {
  content: '\ed3f';
}
.icon-wagon:before {
  content: '\ed40';
}
.icon-aids:before {
  content: '\ed41';
}
.icon-ambulance:before {
  content: '\ed42';
}
.icon-bandage-1:before {
  content: '\ed43';
}
.icon-bandage-2:before {
  content: '\ed44';
}
.icon-blood-1:before {
  content: '\ed45';
}
.icon-blood-2:before {
  content: '\ed46';
}
.icon-brain:before {
  content: '\ed47';
}
.icon-cardio:before {
  content: '\ed48';
}
.icon-cross-circle:before {
  content: '\ed49';
}
.icon-cross-rectangle:before {
  content: '\ed4a';
}
.icon-DNA:before {
  content: '\ed4b';
}
.icon-drugs:before {
  content: '\ed4c';
}
.icon-emergency-call:before {
  content: '\ed4d';
}
.icon-emergency:before {
  content: '\ed4e';
}
.icon-first-aid:before {
  content: '\ed4f';
}
.icon-fitness-app:before {
  content: '\ed50';
}
.icon-handicap:before {
  content: '\ed51';
}
.icon-healthcare:before {
  content: '\ed52';
}
.icon-heart-beat:before {
  content: '\ed53';
}
.icon-hospital-building:before {
  content: '\ed54';
}
.icon-hospital-circle:before {
  content: '\ed55';
}
.icon-hospital-home:before {
  content: '\ed56';
}
.icon-hospital-rectangle:before {
  content: '\ed57';
}
.icon-medical-book:before {
  content: '\ed58';
}
.icon-medical-folder:before {
  content: '\ed59';
}
.icon-medical-tests:before {
  content: '\ed5a';
}
.icon-microscope:before {
  content: '\ed5b';
}
.icon-ointment:before {
  content: '\ed5c';
}
.icon-paramedic:before {
  content: '\ed5d';
}
.icon-pharmacy:before {
  content: '\ed5e';
}
.icon-pill-2:before {
  content: '\ed5f';
}
.icon-pill-3:before {
  content: '\ed60';
}
.icon-pill:before {
  content: '\ed61';
}
.icon-pulse:before {
  content: '\ed62';
}
.icon-spermatosoid:before {
  content: '\ed63';
}
.icon-stethoscope:before {
  content: '\ed64';
}
.icon-stretcher:before {
  content: '\ed65';
}
.icon-surgical-knife:before {
  content: '\ed66';
}
.icon-surgical-scissors:before {
  content: '\ed67';
}
.icon-syringe:before {
  content: '\ed68';
}
.icon-teeth-care:before {
  content: '\ed69';
}
.icon-test-tube-2:before {
  content: '\ed6a';
}
.icon-test-tube:before {
  content: '\ed6b';
}
.icon-thermometer-1:before {
  content: '\ed6c';
}
.icon-toilet-paper:before {
  content: '\ed6d';
}
.icon-tooth:before {
  content: '\ed6e';
}
.icon-weight:before {
  content: '\ed6f';
}
.icon-alien:before {
  content: '\ed70';
}
.icon-biohazard:before {
  content: '\ed71';
}
.icon-bird:before {
  content: '\ed72';
}
.icon-birdhouse:before {
  content: '\ed73';
}
.icon-butterfly:before {
  content: '\ed74';
}
.icon-casino-chip:before {
  content: '\ed75';
}
.icon-coffin:before {
  content: '\ed76';
}
.icon-controller-1:before {
  content: '\ed77';
}
.icon-controller-2:before {
  content: '\ed78';
}
.icon-controller-3:before {
  content: '\ed79';
}
.icon-crossed-bones:before {
  content: '\ed7a';
}
.icon-day-night:before {
  content: '\ed7b';
}
.icon-death:before {
  content: '\ed7c';
}
.icon-dice:before {
  content: '\ed7d';
}
.icon-dream-house:before {
  content: '\ed7e';
}
.icon-eco-house:before {
  content: '\ed7f';
}
.icon-emoticon-grin:before {
  content: '\ed80';
}
.icon-emoticon-smile:before {
  content: '\ed81';
}
.icon-emoticon:before {
  content: '\ed82';
}
.icon-exit:before {
  content: '\ed83';
}
.icon-fence:before {
  content: '\ed84';
}
.icon-fir-tree-1:before {
  content: '\ed85';
}
.icon-fir-tree-2:before {
  content: '\ed86';
}
.icon-fire2:before {
  content: '\ed87';
}
.icon-ghost:before {
  content: '\ed88';
}
.icon-hanging:before {
  content: '\ed89';
}
.icon-happy-mask:before {
  content: '\ed8a';
}
.icon-hipster-1:before {
  content: '\ed8b';
}
.icon-hipster-2:before {
  content: '\ed8c';
}
.icon-house-fire:before {
  content: '\ed8d';
}
.icon-house-lightening:before {
  content: '\ed8e';
}
.icon-house-search:before {
  content: '\ed8f';
}
.icon-incognito:before {
  content: '\ed90';
}
.icon-labyrinth-1:before {
  content: '\ed91';
}
.icon-labyrinth-2:before {
  content: '\ed92';
}
.icon-leaf:before {
  content: '\ed93';
}
.icon-lighthouse:before {
  content: '\ed94';
}
.icon-love:before {
  content: '\ed95';
}
.icon-middle-finger:before {
  content: '\ed96';
}
.icon-moon:before {
  content: '\ed97';
}
.icon-moustache:before {
  content: '\ed98';
}
.icon-no-smoking:before {
  content: '\ed99';
}
.icon-pacman:before {
  content: '\ed9a';
}
.icon-plant:before {
  content: '\ed9b';
}
.icon-playing-cards:before {
  content: '\ed9c';
}
.icon-poison:before {
  content: '\ed9d';
}
.icon-pong:before {
  content: '\ed9e';
}
.icon-poo:before {
  content: '\ed9f';
}
.icon-pool2:before {
  content: '\eda0';
}
.icon-radioactive:before {
  content: '\eda1';
}
.icon-recycle:before {
  content: '\eda2';
}
.icon-robot-1:before {
  content: '\eda3';
}
.icon-robot-2:before {
  content: '\eda4';
}
.icon-rock:before {
  content: '\eda5';
}
.icon-run:before {
  content: '\eda6';
}
.icon-sad-mask:before {
  content: '\eda7';
}
.icon-scythe:before {
  content: '\eda8';
}
.icon-shooting-star:before {
  content: '\eda9';
}
.icon-skull:before {
  content: '\edaa';
}
.icon-smoking:before {
  content: '\edab';
}
.icon-snowflake:before {
  content: '\edac';
}
.icon-snowman:before {
  content: '\edad';
}
.icon-steps:before {
  content: '\edae';
}
.icon-sun:before {
  content: '\edaf';
}
.icon-tetris:before {
  content: '\edb0';
}
.icon-theatre-masks:before {
  content: '\edb1';
}
.icon-tombstone:before {
  content: '\edb2';
}
.icon-tree:before {
  content: '\edb3';
}
.icon-ufo:before {
  content: '\edb4';
}
.icon-unicorn:before {
  content: '\edb5';
}
.icon-vigilante:before {
  content: '\edb6';
}
.icon-wall:before {
  content: '\edb7';
}
.icon-wheat:before {
  content: '\edb8';
}
.icon-account-book-1:before {
  content: '\edb9';
}
.icon-account-book-female:before {
  content: '\edba';
}
.icon-account-book-male:before {
  content: '\edbb';
}
.icon-contacts:before {
  content: '\edbc';
}
.icon-female-sign:before {
  content: '\edbd';
}
.icon-head-brainstorming:before {
  content: '\edbe';
}
.icon-head-idea:before {
  content: '\edbf';
}
.icon-head-money:before {
  content: '\edc0';
}
.icon-head-question:before {
  content: '\edc1';
}
.icon-head-search:before {
  content: '\edc2';
}
.icon-head-settings:before {
  content: '\edc3';
}
.icon-head-speech:before {
  content: '\edc4';
}
.icon-head-time:before {
  content: '\edc5';
}
.icon-head:before {
  content: '\edc6';
}
.icon-ID-card:before {
  content: '\edc7';
}
.icon-male-sign:before {
  content: '\edc8';
}
.icon-people-female:before {
  content: '\edc9';
}
.icon-people-idea:before {
  content: '\edca';
}
.icon-people-male:before {
  content: '\edcb';
}
.icon-people-money:before {
  content: '\edcc';
}
.icon-people-question:before {
  content: '\edcd';
}
.icon-people-speech-1:before {
  content: '\edce';
}
.icon-people-speech-2:before {
  content: '\edcf';
}
.icon-people-target:before {
  content: '\edd0';
}
.icon-people-time:before {
  content: '\edd1';
}
.icon-people:before {
  content: '\edd2';
}
.icon-public-speaking:before {
  content: '\edd3';
}
.icon-rolodex-2:before {
  content: '\edd4';
}
.icon-rolodex:before {
  content: '\edd5';
}
.icon-team-1:before {
  content: '\edd6';
}
.icon-team-2:before {
  content: '\edd7';
}
.icon-team-3:before {
  content: '\edd8';
}
.icon-team-hierarchy:before {
  content: '\edd9';
}
.icon-user-add:before {
  content: '\edda';
}
.icon-user-check:before {
  content: '\eddb';
}
.icon-user-circle:before {
  content: '\eddc';
}
.icon-user-delete:before {
  content: '\eddd';
}
.icon-user-female-add:before {
  content: '\edde';
}
.icon-user-female-check:before {
  content: '\eddf';
}
.icon-user-female-circle:before {
  content: '\ede0';
}
.icon-user-female-delete:before {
  content: '\ede1';
}
.icon-user-female-edit:before {
  content: '\ede2';
}
.icon-user-female-options:before {
  content: '\ede3';
}
.icon-user-female-picture-add:before {
  content: '\ede4';
}
.icon-user-female-picture:before {
  content: '\ede5';
}
.icon-user-female-pictures:before {
  content: '\ede6';
}
.icon-user-female-portrait:before {
  content: '\ede7';
}
.icon-user-female-profile:before {
  content: '\ede8';
}
.icon-user-female-settings:before {
  content: '\ede9';
}
.icon-user-female-speech-1:before {
  content: '\edea';
}
.icon-user-female-speech-2:before {
  content: '\edeb';
}
.icon-user-female:before {
  content: '\edec';
}
.icon-user-male-add:before {
  content: '\eded';
}
.icon-user-male-check:before {
  content: '\edee';
}
.icon-user-male-circle:before {
  content: '\edef';
}
.icon-user-male-delete:before {
  content: '\edf0';
}
.icon-user-male-edit:before {
  content: '\edf1';
}
.icon-user-male-options:before {
  content: '\edf2';
}
.icon-user-male-picture-add:before {
  content: '\edf3';
}
.icon-user-male-picture:before {
  content: '\edf4';
}
.icon-user-male-pictures:before {
  content: '\edf5';
}
.icon-user-male-portrait:before {
  content: '\edf6';
}
.icon-user-male-profile:before {
  content: '\edf7';
}
.icon-user-male-settings:before {
  content: '\edf8';
}
.icon-user-male-speech-1:before {
  content: '\edf9';
}
.icon-user-male-speech-2:before {
  content: '\edfa';
}
.icon-user-male:before {
  content: '\edfb';
}
.icon-user-picture-1:before {
  content: '\edfc';
}
.icon-user-picture-2:before {
  content: '\edfd';
}
.icon-user-picture-add:before {
  content: '\edfe';
}
.icon-user-profile-1:before {
  content: '\edff';
}
.icon-user-profile-2:before {
  content: '\ee00';
}
.icon-user-search-2:before {
  content: '\ee01';
}
.icon-user-target:before {
  content: '\ee02';
}
.icon-user:before {
  content: '\ee03';
}
.icon-users-male-female:before {
  content: '\ee04';
}
.icon-users-male:before {
  content: '\ee05';
}
.icon-users:before {
  content: '\ee06';
}
.icon-VIP-card:before {
  content: '\ee07';
}
.icon-badge-1:before {
  content: '\ee08';
}
.icon-badge-2:before {
  content: '\ee09';
}
.icon-crown:before {
  content: '\ee0a';
}
.icon-diploma-1:before {
  content: '\ee0b';
}
.icon-diploma-2:before {
  content: '\ee0c';
}
.icon-diploma-3:before {
  content: '\ee0d';
}
.icon-flag-1:before {
  content: '\ee0e';
}
.icon-flag-2:before {
  content: '\ee0f';
}
.icon-flag-3:before {
  content: '\ee10';
}
.icon-flag-4:before {
  content: '\ee11';
}
.icon-heart-broken:before {
  content: '\ee12';
}
.icon-heart:before {
  content: '\ee13';
}
.icon-hearts:before {
  content: '\ee14';
}
.icon-like-2:before {
  content: '\ee15';
}
.icon-like:before {
  content: '\ee16';
}
.icon-medal-1:before {
  content: '\ee17';
}
.icon-medal-2:before {
  content: '\ee18';
}
.icon-medal-3:before {
  content: '\ee19';
}
.icon-medal-4:before {
  content: '\ee1a';
}
.icon-medal-5:before {
  content: '\ee1b';
}
.icon-medal-6:before {
  content: '\ee1c';
}
.icon-olympic-torch:before {
  content: '\ee1d';
}
.icon-podium:before {
  content: '\ee1e';
}
.icon-star-circle:before {
  content: '\ee1f';
}
.icon-star-plus:before {
  content: '\ee20';
}
.icon-star:before {
  content: '\ee21';
}
.icon-trophy-1:before {
  content: '\ee22';
}
.icon-trophy-2:before {
  content: '\ee23';
}
.icon-trophy-3:before {
  content: '\ee24';
}
.icon-unlike-2:before {
  content: '\ee25';
}
.icon-unlike:before {
  content: '\ee26';
}
.icon-verification:before {
  content: '\ee27';
}
.icon-votes-2:before {
  content: '\ee28';
}
.icon-votes:before {
  content: '\ee29';
}
.icon-binary-code:before {
  content: '\ee2a';
}
.icon-bug-fixed:before {
  content: '\ee2b';
}
.icon-bug-search:before {
  content: '\ee2c';
}
.icon-bug:before {
  content: '\ee2d';
}
.icon-code-1:before {
  content: '\ee2e';
}
.icon-code-2:before {
  content: '\ee2f';
}
.icon-code-3:before {
  content: '\ee30';
}
.icon-CPU-overclock:before {
  content: '\ee31';
}
.icon-CPU:before {
  content: '\ee32';
}
.icon-firewall-1:before {
  content: '\ee33';
}
.icon-firewall-allert:before {
  content: '\ee34';
}
.icon-firewall-block:before {
  content: '\ee35';
}
.icon-firewall-disable:before {
  content: '\ee36';
}
.icon-firewall-done:before {
  content: '\ee37';
}
.icon-firewall-help:before {
  content: '\ee38';
}
.icon-firewall-refresh:before {
  content: '\ee39';
}
.icon-firewall-star:before {
  content: '\ee3a';
}
.icon-firewall:before {
  content: '\ee3b';
}
.icon-hierarchy-structure-1:before {
  content: '\ee3c';
}
.icon-hierarchy-structure-2:before {
  content: '\ee3d';
}
.icon-hierarchy-structure-3:before {
  content: '\ee3e';
}
.icon-hierarchy-structure-4:before {
  content: '\ee3f';
}
.icon-hierarchy-structure-5:before {
  content: '\ee40';
}
.icon-hierarchy-structure-6:before {
  content: '\ee41';
}
.icon-html-5:before {
  content: '\ee42';
}
.icon-link-1-add:before {
  content: '\ee43';
}
.icon-link-1-broken:before {
  content: '\ee44';
}
.icon-link-1-remove:before {
  content: '\ee45';
}
.icon-link-1:before {
  content: '\ee46';
}
.icon-link-2-broken:before {
  content: '\ee47';
}
.icon-link-2:before {
  content: '\ee48';
}
.icon-link-3-broken:before {
  content: '\ee49';
}
.icon-link-3:before {
  content: '\ee4a';
}
.icon-search-stats:before {
  content: '\ee4b';
}
.icon-window-404:before {
  content: '\ee4c';
}
.icon-window-binary-code:before {
  content: '\ee4d';
}
.icon-window-bookmark:before {
  content: '\ee4e';
}
.icon-window-code:before {
  content: '\ee4f';
}
.icon-window-console:before {
  content: '\ee50';
}
.icon-window-content:before {
  content: '\ee51';
}
.icon-window-cursor:before {
  content: '\ee52';
}
.icon-window-edit:before {
  content: '\ee53';
}
.icon-window-layout:before {
  content: '\ee54';
}
.icon-window-loading:before {
  content: '\ee55';
}
.icon-window-lock:before {
  content: '\ee56';
}
.icon-window-refresh:before {
  content: '\ee57';
}
.icon-window-search:before {
  content: '\ee58';
}
.icon-window-settings:before {
  content: '\ee59';
}
.icon-window-user:before {
  content: '\ee5a';
}
.icon-window:before {
  content: '\ee5b';
}
.icon-windows-open:before {
  content: '\ee5c';
}
