@use '@angular/material' as mat;
@use 'sass:map';
@use './styles.material-palettes' as palettes;

$primary-palette: map.get(palettes.$palettes, primary);
$accent-palette: map.get(palettes.$palettes, accent);
$warn-palette: map.get(palettes.$palettes, warn);

$progress-bar-dark-bg-palettes: ();
@each $name in (primary, accent, warn) {
  $selected-palette: $primary-palette;
  @if $name == primary {
    $selected-palette: $primary-palette;
  }
  @if $name == accent {
    $selected-palette: $accent-palette;
  }
  @if $name == warn {
    $selected-palette: $warn-palette;
  }
  $progress-bar-dark-bg-palettes: map.merge(
    $progress-bar-dark-bg-palettes,
    (
      #{$name}:
        mat.define-palette(
          map.merge(
            $selected-palette,
            (
              500: rgb(var(--palette-white-500)),
              contrast: (),
            )
          )
        )
    )
  );
}

$dark-bg-theme: mat.define-dark-theme(
  (
    color: $progress-bar-dark-bg-palettes,
  )
);

.dark-bg {
  @include mat.legacy-progress-bar-color($dark-bg-theme);
}

/* MDC override background buffer */
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgb(var(--palette-primary-100));
}
