@import 'styles.mixins';

// Target Material, Simple Line and SVG icons.
[class^='icon-'],
mat-icon,
svg {
  &.size-small {
    @include icon-size($size: 'small');
  }
  &.size-regular {
    @include icon-size($size: 'regular');
  }
  &.size-medium {
    @include icon-size($size: 'medium');
  }
  &.size-large {
    @include icon-size($size: 'large');
  }
  &.color-light {
    @include icon-color($color: 'light');
    &.inactive {
      @include icon-color($color: 'light', $inactive: true);
    }
  }
  &.color-dark {
    @include icon-color($color: 'dark');
    &.inactive {
      @include icon-color($color: 'dark', $inactive: true);
    }
  }
}
