// ============================================================================
//
//  Because of our approach to client theming where we provide CSS variables
//  which are only known at runtime we need to be mindful of a trade-off which
//  applies occassionally when using Angular Material.
//
//  Occasionally, instead of picking an alternative color from your palette
//  Angular Material will take a particular color (e.g. usually 500) and apply
//  conversion of it to rgba() and apply a custom opacity to alter the color's
//  appearance. This only works for static hex codes known at build time.
//
//  We cannot leverage this default behavior because we do not have static hex
//  code known at build time, we have CSS variables in our themes which get
//  passed through.
//
//  Fortunately, to work around this it is simply a matter of tracking down the
//  rare occurrences of these opacity transformations and replacing them with
//  an appropriate alternative color from the palette. E.g. primary-500 with an
//  opacity of .3 can be replaced with primary-100.
//
//  NOTE: These occurrences should be flagged up to Design so that they can
//  update any Morpho DLS specs accordingly.
//
// ============================================================================

mat-datepicker-content {
  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgb(var(--palette-primary-100));
  }

  .mat-calendar-body-cell.mat-calendar-body-in-range::before {
    background-color: rgb(var(--palette-primary-50));
  }
}
