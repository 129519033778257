@import 'styles.variables';
@import 'styles.typography';
@import 'styles.padding';
@import 'styles.margin';
@import 'styles.icons';
@import 'styles.buttons';
@import 'styles.form-fields';

html,
body {
  min-height: 100%;
  margin: 0 0;
}

// Opacity transition effect
.translucent {
  transition: opacity 1s;
  &.trans0 {
    opacity: 1;
  }
  &.trans1 {
    opacity: 0.95;
  }
  &.trans2 {
    opacity: 0.8;
  }
}

// Spin animation
.animate-spin {
  animation: animate-spin 2s infinite linear;
}
@keyframes animate-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.vh60 {
  height: 60vh;
}

.vh80 {
  height: 80vh;
}

.vh100 {
  height: 100vh;
}

.full-screen-dialog {
  .mat-mdc-dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh !important;
    width: 100vw !important;
    max-height: none !important;
    max-width: none !important;
  }
}
