.dls-menu-list-item-menu {
  .mat-menu-item {
    height: unset;
    line-height: unset;

    .mat-icon {
      margin-right: 0;
    }
  }

  .mat-list-base {
    zelis-list-item {
      .mat-list-item {
        .mat-list-item-content {
          padding-left: 0;
          padding-right: 0;
        }

        &.mat-2-line {
          height: auto;
        }
      }
    }
  }
}
