@use '@angular/material' as mat;
@use 'sass:map';
@use './styles.material-palettes' as palettes;

.mat-form-field {
  &.mat-form-field-invalid {
    .mat-icon {
      color: rgb(var(--palette-m3-system-red-40));
    }
  }

  &.mat-form-field-disabled {
    &.mat-form-field-appearance-standard,
    &.mat-form-field-appearance-fill,
    &.mat-form-field-appearance-outline {
      .mat-form-field-underline {
        height: 1px;
        background-image: none;
        background-color: rgb(var(--palette-m3-system-gray-75));
      }

      .mat-icon,
      label,
      .mat-hint {
        color: rgb(var(--palette-m3-system-gray-75));
      }
    }

    &.mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        color: rgb(var(--palette-m3-system-gray-75));
      }
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: rgb(var(--palette-m3-system-gray-35));
    }
  }
}

$dark-bg-theme: mat.define-dark-theme(
  (
    color: palettes.$white-palettes,
  )
);

.dark-bg {
  @include mat.legacy-form-field-color($dark-bg-theme);
  @include mat.legacy-select-color($dark-bg-theme);
  @include mat.legacy-checkbox-color($dark-bg-theme);

  // Overriding Material's gray fill for dark backgrounds until we build our own "form-field" components....
  .mdc-text-field--filled.mdc-text-field--disabled {
    background-color: rgba(255, 255, 255, 0.05) !important;

    .mdc-floating-label,
    .mdc-label,
    .mat-mdc-select-arrow,
    .mat-mdc-select-value-text,
    .mat-mdc-input-element {
      color: rgba(var(--palette-m3-system-gray-90), 0.8) !important;
    }

    .mdc-line-ripple:before {
      border-bottom-color: rgba(
        var(--palette-m3-system-gray-90),
        0.8
      ) !important;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: rgba(255, 255, 255, 0.09) !important;

    .mdc-floating-label,
    .mdc-label,
    .mat-mdc-select-arrow,
    .mat-mdc-select-value-text,
    .mat-mdc-input-element {
      color: inherit;
    }

    .mdc-line-ripple:before {
      border-bottom-color: rgb(var(--palette-m3-system-gray-95)) !important;
    }
  }

  .no-underline {
    .mat-form-field-underline {
      height: 0px;
      background-image: none;
      background-color: transparent;
      opacity: 0;
    }
  }
}
