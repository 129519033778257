@use '@angular/material' as mat;
@use 'sass:map';

$stroke-color: rgb(var(--palette-m3-system-gray-85));

.zelis-expansion-panel {
  display: block;
  box-shadow: none !important;

  .mat-expansion-panel-header {
    .mat-content,
    .mat-expansion-panel-header-title {
      align-items: center;
    }
  }

  .mat-expansion-indicator::after {
    border-color: var(--palette-m3-system-gray-35);
  }

  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
  &:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background-color: rgb(var(--palette-m3-system-gray-85));
  }

  .mat-expansion-panel-body {
    @apply py-6;
  }
}

.mat-accordion {
  &.stroke {
    .zelis-expansion-panel {
      border: 1px solid $stroke-color;

      &:not(.mat-expanded) {
        border-top-width: 0;
        border-bottom-width: 0;
      }

      &.previous-sibling-expanded {
        border-top-color: $stroke-color;
        border-top-width: 1px;
      }

      &:first-of-type {
        border-top-color: $stroke-color;
        border-top-width: 1px;
      }

      &:last-of-type {
        border-bottom-color: $stroke-color;
        border-bottom-width: 1px;
      }
    }
  }

  &.static-dividers,
  &.shifting-dividers {
    .zelis-expansion-panel {
      .mat-expansion-panel-header {
        border-bottom: 1px solid $stroke-color;

        &.mat-expanded {
          border-radius: 0;
        }
      }

      &:last-of-type {
        .mat-expansion-panel-header {
          &:not(.mat-expanded) {
            border-bottom-color: transparent;
          }
        }
      }
    }

    &.stroke {
      .zelis-expansion-panel {
        &:not(.mat-expanded) {
          &.next-sibling-expanded {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }

  &.shifting-dividers {
    .zelis-expansion-panel {
      .mat-expansion-panel-header {
        &.mat-expanded {
          border-bottom-color: transparent;
        }
      }

      &.mat-expanded {
        border-bottom: 1px solid $stroke-color;
      }
    }
  }

  .zelis-expansion-panel {
    @apply rounded-lg;

    &:first-of-type {
      @apply rounded-t-lg;
      border-top-color: $stroke-color;
    }

    &:last-of-type {
      @apply rounded-b-lg;
      border-bottom-color: $stroke-color;
    }
  }
}
