.zelis-badge {
  position: relative;
  overflow: visible !important;

  .zelis-badge-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: 0px;
    border-radius: 100px;
    font-size: 12px;
    padding: 0 4.5px;
    border: 1px solid rgba(var(--palette-primary-contrast-500));
    line-height: 16px;
    transform: translateX(100%);
    height: 18px;
    min-width: 18px;
    background: rgba(var(--palette-m3-system-red-40));
    color: rgba(var(--palette-primary-contrast-500));
    z-index: 5;

    &.zelis-badge-empty {
      color: transparent;
      min-width: 8px;
      height: 8px;
      padding: 0;
      top: -4px;
      right: 0;
    }
  }

  &.zelis-badge-default .zelis-badge-content {
    background: rgba(var(--palette-m3-system-gray-85));
    color: rgba(var(--palette-m3-system-gray-35));
  }
  &.zelis-badge-primary .zelis-badge-content {
    background: rgba(var(--palette-m3-system-blue-40));
  }
  &.zelis-badge-success .zelis-badge-content {
    background: rgba(var(--palette-m3-system-green-40));
  }
  &.zelis-badge-warn .zelis-badge-content {
    background: rgba(var(--palette-m3-system-red-40));
  }
}

zelis-button.zelis-badge .zelis-badge-content {
  top: -18px;
  right: 9px;
}

mat-icon.zelis-badge .zelis-badge-content {
  top: -5px;
  right: 12px;

  &.zelis-badge-empty {
    top: -2px;
    right: 7px;
  }
}
