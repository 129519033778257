@import 'styles.variables';
@import 'styles.mixins';

.pad {
  $maxTimes: 10;
  $i: 1;
  @while $i <= $maxTimes {
    &.all-#{$i}x {
      @include padding($top: $i, $right: $i, $bottom: $i, $left: $i);
    }
    &.x-#{$i}x {
      @include padding($x: $i);
    }
    &.y-#{$i}x {
      @include padding($y: $i);
    }
    &.top-#{$i}x {
      @include padding($top: $i);
    }
    &.right-#{$i}x {
      @include padding($right: $i);
    }
    &.bottom-#{$i}x {
      @include padding($bottom: $i);
    }
    &.left-#{$i}x {
      @include padding($left: $i);
    }
    $i: $i + 1;
  }
}
