/**
 * Extend other styles with: @extend .class-name
*/

.external-link-indicator-icon {
  display: inline-flex;

  &:after {
    content: '\e89e';
    font-family: 'Material Icons';
    display: inline-block;
    vertical-align: bottom;
    color: inherit;
    font-size: inherit;
    @include margin($left: 0.5);
  }
}
