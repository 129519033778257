@import '../styles/styles.variables';

@mixin padding(
  $all: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $x: null,
  $y: null,
  $topOffset: 0,
  $rightOffset: 0,
  $bottomOffset: 0,
  $leftOffset: 0,
  $important: false
) {
  $importantVal: null;
  @if $important {
    $importantVal: '!important';
  }
  @if $all {
    $padTop: ($padding-base * $all) + $topOffset;
    $padRight: ($padding-base * $all) + $rightOffset;
    $padBottom: ($padding-base * $all) + $bottomOffset;
    $padLeft: ($padding-base * $all) + $leftOffset;

    padding: $padTop $padRight $padBottom $padLeft #{$importantVal};
  }
  @if $x {
    $padRight: ($padding-base * $x) + $rightOffset;
    $padLeft: ($padding-base * $x) + $leftOffset;
    padding-right: $padRight #{$importantVal};
    padding-left: $padLeft #{$importantVal};
  }
  @if $y {
    $padTop: ($padding-base * $y) + $topOffset;
    $padBottom: ($padding-base * $y) + $bottomOffset;
    padding-top: $padTop #{$importantVal};
    padding-bottom: $padBottom #{$importantVal};
  }
  @if $top {
    padding-top: (($padding-base * $top) + $topOffset) #{$importantVal};
  }
  @if $right {
    padding-right: (($padding-base * $right) + $rightOffset) #{$importantVal};
  }
  @if $bottom {
    padding-bottom: (($padding-base * $bottom) + $bottomOffset)
      #{$importantVal};
  }
  @if $left {
    padding-left: (($padding-base * $left) + $leftOffset) #{$importantVal};
  }
}

@mixin margin(
  $all: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $x: null,
  $y: null,
  $topOffset: 0,
  $rightOffset: 0,
  $bottomOffset: 0,
  $leftOffset: 0,
  $important: false
) {
  $importantVal: null;
  @if $important {
    $importantVal: '!important';
  }
  @if $all {
    $mgnTop: ($margin-base * $all) + $topOffset;
    $mgnRight: ($margin-base * $all) + $rightOffset;
    $mgnBottom: ($margin-base * $all) + $bottomOffset;
    $mgnLeft: ($margin-base * $all) + $leftOffset;

    margin: $mgnTop $mgnRight $mgnBottom $mgnLeft #{$importantVal};
  }
  @if $x {
    $mgnRight: ($margin-base * $x) + $rightOffset;
    $mgnLeft: ($margin-base * $x) + $leftOffset;
    margin-right: $mgnRight #{$importantVal};
    margin-left: $mgnLeft #{$importantVal};
  }
  @if $y {
    $mgnTop: ($margin-base * $y) + $topOffset;
    $mgnBottom: ($margin-base * $y) + $bottomOffset;
    margin-top: $mgnTop #{$importantVal};
    margin-bottom: $mgnBottom #{$importantVal};
  }
  @if $top {
    margin-top: (($margin-base * $top) + $topOffset) #{$importantVal};
  }
  @if $right {
    margin-right: (($margin-base * $right) + $rightOffset) #{$importantVal};
  }
  @if $bottom {
    margin-bottom: (($margin-base * $bottom) + $bottomOffset) #{$importantVal};
  }
  @if $left {
    margin-left: (($margin-base * $left) + $leftOffset) #{$importantVal};
  }
}

@mixin icon-size($size: 'regular', $custom: null) {
  $sizeMap: (
    'small': 18px,
    'regular': 24px,
    'medium': 36px,
    'large': 48px,
  );
  display: inline-block;
  @if $size {
    font-size: map-get($sizeMap, $size);
    height: map-get($sizeMap, $size);
    width: map-get($sizeMap, $size);
  } @else if $custom {
    font-size: $custom;
    height: $custom;
    width: $custom;
  }
}

@mixin icon-color($color: 'dark', $inactive: false) {
  display: inline-block;
  opacity: 1;
  @if $color == 'light' {
    color: $app-white;
    @if $inactive {
      opacity: 0.3;
    }
  } @else if $color == 'dark' {
    color: $app-black;
    opacity: 0.54;
    @if $inactive {
      opacity: 0.26;
    }
  } @else {
    color: $color;
  }
}

@function tint($color, $percentage) {
  @return mix($app-white, $color, $percentage);
}

@mixin scrollbar(
  $width: 4px,
  $trackColor: $gray-200,
  $handleColor: $gray-400,
  $handleColorHover: $gray-500
) {
  /* Width */
  &::-webkit-scrollbar {
    width: $width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $trackColor;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $handleColor;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $handleColorHover;
  }
}
