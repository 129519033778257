// Don't forget to import this in index.html
// <link href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i" rel="stylesheet">

// Palettes
@import 'styles.palettes';
@import 'styles.palette-mixins';
$app-palette-primary: platform-palette($platform-palette-primary) !default;
$app-palette-accent: platform-palette($platform-palette-accent) !default;
$app-palette-gray: platform-palette($platform-palette-gray) !default;
$app-palette-warning: platform-palette($platform-palette-warning) !default;
$app-palette-smartshopper: platform-palette(
  $platform-palette-smartshopper
) !default;

// Colors
$app-color-primary: platform-palette-color($app-palette-primary, 500) !default;
$app-color-accent: platform-palette-color($app-palette-accent, 500) !default;
$app-color-accent-100: platform-palette-color(
  $app-palette-accent,
  100
) !default;
$app-color-accent-400: platform-palette-color(
  $app-palette-accent,
  400
) !default;
$app-color-primary-100: platform-palette-color($app-palette-primary, 100);
$app-color-primary-300: platform-palette-color($app-palette-primary, 300);
$app-color-primary-600: platform-palette-color($app-palette-primary, 600);
$app-color-smartshopper: platform-palette-color(
  $app-palette-smartshopper,
  500
) !default;
$app-color-warning: platform-palette-color($app-palette-warning, 500) !default;
$app-sapphire-logo: #2691ff;

$gray-50: platform-palette-color($app-palette-gray, 50) !default;
$gray-100: platform-palette-color($app-palette-gray, 100) !default;
$gray-200: platform-palette-color($app-palette-gray, 200) !default;
$gray-300: platform-palette-color($app-palette-gray, 300) !default;
$gray-400: platform-palette-color($app-palette-gray, 400) !default;
$gray-500: platform-palette-color($app-palette-gray, 500) !default;
$gray-600: platform-palette-color($app-palette-gray, 600) !default;
$gray-700: platform-palette-color($app-palette-gray, 700) !default;
$gray-800: platform-palette-color($app-palette-gray, 800) !default;
$gray-900: platform-palette-color($app-palette-gray, 900) !default;
// Blue-grays deprecated. Use gray palette.
$blue-gray: platform-palette-color($app-palette-gray, 900) !default;
$blue-gray-100: platform-palette-color($app-palette-gray, 100) !default;

$app-link-color: $app-color-accent !default;
$app-background-color-medium: $gray-200 !default;
$app-background-color-dark: $gray-300 !default;
$app-rating-on-color: #f56000;

$app-white: #fff;
$app-black: #000;

// Headings
$app-h1-size: 48px !default;
$app-h1-line-height: 56px !default;
$app-h2-size: 32px !default;
$app-h2-line-height: 40px !default;
$app-h3-size: 24px !default;
$app-h3-line-height: 32px !default;
$app-h4-size: 18px !default;
$app-h4-line-height: 24px !default;
$app-h5-size: 14px !default;
$app-h5-line-height: 24px !default;
$app-h6-size: 14px !default;
$app-h6-line-height: 24px !default;

// Headings - mobile overrides
$app-h1-mobile-size: 46px !default;
$app-h5-mobile-size: 16px !default;

// List
$app-li-line-height: 40px !default;

// Buttons
$app-button-font-size: 16px !default;
$app-button-line-height: 48px !default;
$app-button-small-font-size: 14px !default;
$app-button-small-line-height: 32px !default;
$app-stroked-button-border-color: $gray-200 !default;
$app-stroked-button-active-color: $gray-300 !default;
$app-filter-button-border-color-light: $app-white !default;
$app-filter-button-active-color-light: $app-white !default;
$app-filter-button-border-color-dark: $app-black !default;
$app-filter-button-active-color-dark: $app-black !default;
$app-button-hero-line-height: 64px !default;
$app-button-hero-color: $app-white !default;
$app-button-hero-text-color: $app-black !default;
$app-button-spinner-width: 32px !default;
$app-button-radius: 4px !default;

// Fonts
$app-font-size: 14px !default;
$app-line-height: 24px !default;
$app-text-color-light: $app-white !default;
$app-text-color-dark: $gray-900 !default;
$app-text-color-emphasis: $gray-700 !default;
$app-text-color-underemphasis: $gray-500 !default;
$app-text-color-detract: $gray-600 !default;

$app-font-weight-light: 300 !default;
$app-font-weight-regular: 400 !default;
$app-font-weight-medium: 500 !default;
$app-font-weight-bold: 700 !default;

$app-micro-font-size: 10px !default;
$app-micro-line-height: 14px !default;
$app-small-font-size: 12px !default;
$app-small-line-height: 16px !default;
$app-small-text-color: $app-text-color-underemphasis !default;

// Spacing
$padding-base: 8px !default;
$margin-base: 8px !default;

/**
 * App Style Variables
 */

$error-color: #f44336 !default;
$success-color: #4caf50 !default;
$input-border-color: #9e9e9e !default;
$link-color: #039be5 !default;

$input-border: 1px solid $input-border-color !default;
$input-font-size: 1rem !default;
$input-height: 3rem !default;
$input-margin: 0 0 0 0 !default;
$input-padding: 0 !default;
$input-transition: all 0.3s !default;
$label-font-size: 0.8rem !default;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: 'only screen and (min-width : #{$small-screen-up})' !default;
$large-and-up: 'only screen and (min-width : #{$medium-screen-up})' !default;
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default;
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default;
$medium-only: 'only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})' !default;
