.chip > .mat-mdc-chip.mdc-evolution-chip.mat-mdc-standard-chip {
  &.mat-mdc-chip-disabled {
    opacity: 1;
  }
}

.mdc-evolution-chip__graphic.mat-mdc-chip-graphic {
  display: none;
}

.mat-mdc-chip.mdc-evolution-chip.mat-mdc-standard-chip {
  background-color: rgba(var(--palette-m3-system-gray-contrast-10));
  color: rgb(var(--palette-m3-system-gray-10));

  &.cdk-keyboard-focused,
  &:hover {
    background-color: rgb(var(--palette-m3-system-gray-90));
  }
  &:active {
    background-color: rgb(var(--palette-m3-system-gray-85));
  }
}

.mat-mdc-chip-focus-overlay {
  background-color: transparent;
}

.mat-mdc-chip.mdc-evolution-chip.mat-mdc-standard-chip.selected {
  background-color: rgb(var(--palette-m3-system-blue-95));
  color: rgb(var(--palette-m3-system-blue-30));
  border: solid 1px rgb(var(--palette-m3-system-blue-30)) !important;

  &.cdk-keyboard-focused,
  &:hover {
    background-color: rgb(var(--palette-m3-system-blue-90));
  }
  &:active {
    background-color: rgb(var(--palette-m3-system-blue-85));
  }
}

.mat-mdc-chip-focus-overlay.selected {
  background-color: rgb(var(--palette-m3-system-blue-85));
}

.mat-mdc-chip.mdc-evolution-chip.mat-mdc-standard-chip.selected:not(.mdc-evolution-chip--disabled, .mat-mdc-chip-highlighted)
  .mdc-evolution-chip__text-label {
  color: rgb(var(--palette-m3-system-blue-30));
}

// autosuggest chips
.chip-filters-container {
  .mat-mdc-chip.mat-mdc-chip-option {
    background-color: rgb(var(--palette-m3-system-blue-95));
    padding: 8px;
    &.cdk-keyboard-focused,
    &:hover {
      background-color: rgb(var(--palette-m3-system-blue-90));
    }
    &.mat-mdc-chip-selected.mdc-evolution-chip--selected {
      background-color: rgb(var(--palette-m3-system-blue-40));
      .mdc-evolution-chip__text-label {
        color: white;
      }
      &.cdk-keyboard-focused,
      &:hover {
        background-color: rgb(var(--palette-m3-system-blue-30));
      }
    }
    &.mat-mdc-chip-highlighted {
      background-color: rgb(var(--palette-m3-system-blue-30));
    }
  }
}
