// ============================================================================
//
//  Because of our approach to client theming where we provide CSS variables
//  which are only known at runtime we need to be mindful of a trade-off which
//  applies occassionally when using Angular Material.
//
//  Occassionally, instead of picking an alternative color from your palette
//  Angular Material will take a particular color (e.g. usually 500) and apply
//  conversion of it to rgba() and apply a custom opacity to alter the color's
//  appearance. This only works for static hex codes known at build time.
//
//  We cannot leverage this default behavior because we do not have static hex
//  code known at build time, we have CSS variables in our themes which get
//  passed through.
//
//  Fortunately, to work around this it is simply a matter of tracking down the
//  rare occurrences of these opacity transformations and replacing them with
//  an approriate alternative color from the palette. E.g. primary-500 with an
//  opacity of .3 can be replaced with primary-100.
//
//  NOTE: These occurrences should be flagged up to Design so that they can
//  update any Morpho DLS specs accordingly.
//
// ============================================================================

mat-slide-toggle.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked {
  //KEEP PRIMARY AND ACCENT OPTIONS AVAILABLE FOR SMARTSHOPPER
  &.mat-primary {
    .mdc-switch__handle-track .mdc-switch__handle::after {
      background-color: rgb(var(--palette-primary-900));
    }

    button {
      &:hover,
      &:active,
      &:focus {
        .mdc-switch__ripple::after {
          opacity: 0.1;
        }
      }
    }
  }

  &.mat-accent {
    .mdc-switch__handle-track .mdc-switch__handle::after {
      background-color: rgb(var(--palette-accent-900));
    }

    button {
      &:hover,
      &:active,
      &:focus {
        .mdc-switch__ripple::after {
          opacity: 0.1;
        }
      }
    }
  }

  &.mat-system-blue {
    .mdc-switch__track::after {
      background-color: rgb(var(--palette-m3-system-blue-70));
    }
    .mdc-switch__handle-track .mdc-switch__handle::after {
      background-color: rgb(var(--palette-m3-system-blue-30));
    }

    button {
      &:hover,
      &:active,
      &:focus {
        .mdc-switch__track::after {
          background-color: rgb(var(--palette-m3-system-blue-70)) !important;
        }
        .mdc-switch__ripple::after {
          background-color: rgb(var(--palette-m3-system-blue-40)) !important;
          opacity: 0.1;
        }
      }
    }
  }
}
