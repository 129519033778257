@import '../../../libs/shared/client-configuration/src/generated/default/css-vars.scss';
@import '../../../libs/shared/theming/src/lib/core/_styles.tailwind.scss';
@import '../../../libs/shared/theming/src/generated/angular-material-css-vars/styles.css';
@import '../../../libs/shared/theming/src/lib/core/_styles.material-components.scss';
@import '_styles.variables.scss';
@import '_styles.material.theme.scss';
@import '_styles.fonts.scss';
@import '_styles.animations.scss';
@import '_styles.base.scss';
@import '_styles.components.scss';
@import '_styles.loaders.scss';
@import '_styles.pui-comps.global.scss';
@import '_styles.material-overrides.scss';

:root {
  --disclaimer-color: #{$disclaimer-color};
  --header-text-color: #{$header-text-color};

  --cost-compare-lighten: rgb(var(--palette-primary-50));
  --primary-color-light-lighten: rgb(var(--palette-primary-50));
}
