.mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 1.5rem;
  border-radius: 8px;

  .mat-mdc-dialog-title {
    padding: 0;
    margin-bottom: 1.25rem;
    letter-spacing: normal;

    &::before {
      display: none;
    }

    .mat-icon {
      height: 18px;
      width: 18px;
    }
  }

  .mat-mdc-dialog-content {
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
  }

  .mat-mdc-dialog-actions {
    margin-bottom: -1.5rem;
    padding: 0.5rem 0;
    border: none;
    box-sizing: content-box;
  }
}

.cdk-overlay-pane.full-screen {
  height: 100% !important;
  width: 100% !important;
  max-height: 100% !important;

  .mat-mdc-dialog-actions {
    flex-direction: column;
    @apply space-y-4;

    button {
      margin: 0;
    }
  }
}
