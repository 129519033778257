.horizontal-divider,
.vertical-divider {
  @apply divide-black divide-opacity-10;

  &.solid-divider {
    @apply divide-opacity-100;
  }

  &.light-divider {
    @apply divide-white;

    &:not(.solid-divider) {
      @apply divide-opacity-20;
    }
  }
}

.horizontal-divider {
  @apply divide-y;
}

.vertical-divider {
  @apply divide-x;
}

.medium-divider {
  &.horizontal-divider {
    @apply divide-y-2;
  }

  &.vertical-divider {
    @apply divide-x-2;
  }
}

.thick-divider {
  &.horizontal-divider {
    @apply divide-y-4;
  }

  &.vertical-divider {
    @apply divide-x-4;
  }
}

.dotted-divider {
  &.horizontal-divider {
    @apply divide-y divide-dashed;
  }

  &.vertical-divider {
    @apply divide-x divide-dashed;
  }
}
